import axios from "axios";
import WarningToastify from "../../UI/common/WarningToastify";

const GetPfpList = async () => {
  try {
    const url = process.env.REACT_APP_SERVER_API + "/pfps/random?size=5";
    const res = await axios.get(url);
    return res.data.data.pfpUrl;
  } catch (error: any) {
    if (error.response.data.message) {
      WarningToastify(error.response.data.message);
      return;
    } else {
      alert("알 수 없는 에러가 발생했습니다.");
      return;
    }
  }
};

export default GetPfpList;
