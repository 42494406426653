import { useNavigate } from "react-router-dom";
import six_steps_logo from "../../../../common/images/6_steps.jpeg";
import KakaoSVG from "../../../../common/svg/KakaoSVG";
import NotiToastify from "../../common/NotiToastify";
import SocialRegisterUser from "../../../Feat/auth/SocialRegisterUser";
import { useEffect } from "react";
import SocialRegisterAgency from "../../../Feat/auth/SocialRegisterAgency";

const RegisterMain = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (sessionStorage.getItem("login_user")) {
      alert("이미 로그인된 상태입니다.");
      window.location.href = "/";
    }
  }, []);
  return (
    <div className="flex flex-col min-h-screen items-center ">
      <img src={six_steps_logo} className="w-[140px] h-[110px] mt-[60px]" />
      <h3 className="text-gray100 text-[20px] mt-4">회원가입</h3>

      <div className="flex flex-col items-center text-gray100 mt-[40px]">
        <h3>
          * 회원가입 시 메타마스크/카이카스 또는 왓콘 지갑 연동이 필요합니다.{" "}
        </h3>
        <h5 className="mt-[2px]">
          메타마스크/카이카스 지갑이 없다면 아래 링크를 통해 만든 후 회원가입
          해주세요.
        </h5>

        <div className="flex  cursor-pointer space-x-5 mt-[30px]">
          <div
            className="text-[#F5841F] underline"
            onClick={() => {
              window.open(
                "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=ko"
              );
            }}
          >
            메타마스크에서 지갑 만들기
          </div>
          <div
            className="text-kaikas_color underline"
            onClick={() => {
              window.open(
                "https://chrome.google.com/webstore/detail/kaikas/jblndlipeogpafnldhgmapagcccfchpi?hl=ko"
              );
            }}
          >
            카이카스에서 지갑 만들기
          </div>
        </div>
        <div className="my-[20px] flex flex-col items-center justify-center border border-gray-400 p-2">
          <span className="">
            왓콘 지갑 연동시 <b>원화결제</b> 가 가능합니다.
          </span>
          <span>
            왓콘 지갑 연동을 원하시면 카카오로 회원가입을 진행해주세요.
          </span>
        </div>
      </div>
      <div className="flex  content-between justify-between w-[640px] mt-[60px]">
        <div className="flex flex-col items-center">
          <h3 className="text-gray100 text-[18px]">개인 회원가입 </h3>
          <button
            type="button"
            onClick={() => {
              navigate("/register/personal/email");
            }}
            className=" mt-[40px] bg-brand100 text-white text-center rounded-[10px] w-[210px] h-[50px]"
          >
            이메일로 시작하기
          </button>
          <button
            type="button"
            onClick={() => {
              SocialRegisterUser();
            }}
            className="mt-[16px] flex items-center justify-center  bg-[#FEE500] text-black text-center rounded-[10px] w-[210px] h-[50px]"
          >
            <KakaoSVG width={20} height={20} fillColor="black" />
            <span className="ml-4"> 카카오로 시작하기</span>
          </button>
        </div>

        <div className="flex flex-col items-center">
          <h3 className="text-gray100 text-[18px]">기관 회원가입 </h3>
          <button
            type="button"
            onClick={() => {
              navigate("/register/institution");
            }}
            className=" mt-[40px] bg-brand100 text-white text-center rounded-[10px] w-[210px] h-[50px]"
          >
            사업자 등록번호로 시작하기
          </button>
          <button
            type="button"
            onClick={() => {
              SocialRegisterAgency();
            }}
            className="mt-[16px] flex items-center justify-center  bg-[#FEE500] text-black text-center rounded-[10px] w-[210px] h-[50px]"
          >
            <KakaoSVG width={20} height={20} fillColor="black" />
            <span className="ml-4"> 카카오로 시작하기</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterMain;
