import axios from "axios";
import { useRef, useState } from "react";
import NotiToastify from "../../../components/UI/common/NotiToastify";
import UploadSVG from "../../../common/svg/UploadSVG";
import six_steps_logo from "../../../common/images/6_steps.jpeg";
import { useRecoilValue } from "recoil";
import { signUpAgencyState } from "../../../utils/recoil/auth/register/atom";
import WarningToastify from "../../../components/UI/common/WarningToastify";
import Modal from "../../../components/UI/common/Modal";
import PfpModalContent from "../../../components/UI/auth/register/common/PfpModalContent";
import SearchAddressModalContent from "../../../components/UI/auth/register/institution/SearchAddressModalContent";
const SocialRegisterAgency = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState("");
  const [agencyType, setAgencyType] = useState("RIGHTS_GROUP");
  const [address, setAddress] = useState<string>("");
  const [isActiveSearchAddressPopUp, setIsActiveSearchAddressPopUp] =
    useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const agencyEmail = useRecoilValue(signUpAgencyState).email;
  const [imageUrl, setImageUrl] = useState("");
  const [isActivePfpModal, setIsActivePfpModal] = useState(false);
  const [businessLicenseNum, setBusinessLicenseNum] = useState("");

  let selectedFile: any;

  const signUp = async () => {
    if (
      name !== "" &&
      agencyEmail !== "" &&
      // walletList.length !== 0 &&
      imageUrl !== ""
    ) {
      try {
        const url =
          process.env.REACT_APP_BLOCKCHAIN_API + "/api/generateWallets";
        const res = await axios.get(url);
        const data = {
          name,
          email: agencyEmail,
          image: imageUrl,
          businessLicense: businessLicenseNum,
          agencyType,
          latitude,
          longitude,
          walletList: [
            {
              walletType: "WHATCON",
              walletAddress: `${res.data.address}::${res.data.privateKey}`,
            },
          ],
        };

        const response = await axios.post(
          process.env.REACT_APP_SERVER_API + "/auth/agency/sign-up",
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
            },
          }
        );
        if (response.data.status == 200) {
          NotiToastify("회원가입이 완료되었습니다. ");
          window.location.href = "/register/success";
        }
      } catch (error: any) {
        if (error.response.data.message) {
          NotiToastify(error.response.data.message);
        } else {
          WarningToastify("회원가입에 실패했습니다.");
          return;
        }
      }
    } else if (businessLicenseNum.length !== 12) {
      NotiToastify("사업자 등록번호를 형식에 맞게 입력해주세요.");
      return;
    } else {
      NotiToastify("회원가입 폼을 모두 입력해주세요");
    }
  };

  const requestImgUrl = async () => {
    try {
      const formData = new FormData();
      if (selectedFile !== null) {
        formData.append("image", selectedFile);

        const response = await axios.post(
          process.env.REACT_APP_SERVER_API + "/files/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response.data.data.url) {
          setImageUrl(response.data.data.url);
          NotiToastify("이미지 업로드가 되었습니다.");
          return true;
        }
      }
    } catch (error) {
      console.error("파일 업로드 오류:", error);
      NotiToastify("파일 업로드에 실패했습니다.");
      return false;
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    selectedFile = e.target.files?.[0] || null;
    setFile(selectedFile);
    if (selectedFile !== null) {
      const requestImgUrlResult: any = await requestImgUrl();
    }
  };

  const handleBusinessLicenseNumChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = e.target.value.replace(/\D/g, "");
    if (inputValue.length >= 10) {
      const formattedValue = inputValue
        .slice(0, 10)
        .replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
      setBusinessLicenseNum(formattedValue);
    } else {
      setBusinessLicenseNum(inputValue);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <img src={six_steps_logo} className="w-[120px] h-[100px] mt-[60px]" />
      <Modal
        content={
          <SearchAddressModalContent
            setAddress={setAddress}
            setLongitude={setLongitude}
            setLatitude={setLatitude}
            setIsActiveSearchAddressPopUp={setIsActiveSearchAddressPopUp}
            isActiveSearchAddressPopUp={isActiveSearchAddressPopUp}
          />
        }
        isActiveModal={isActiveSearchAddressPopUp}
        closeModal={setIsActiveSearchAddressPopUp}
      />
      <Modal
        content={
          <PfpModalContent
            setImgUrl={setImageUrl}
            setIsActivePfpModal={setIsActivePfpModal}
          />
        }
        isActiveModal={isActivePfpModal}
        closeModal={setIsActivePfpModal}
      />
      <h3 className="mt-[10px]">회원 정보 입력</h3>
      <div className="flex flex-col  mt-[120px]">
        <div className="flex mb-[50px]">
          <span className="register-form-name">회원 유형</span>
          <div className="bg-gray40 rounded-[20px] w-[120px] h-[32px] flex items-center justify-center text-center text-gray80">
            기관 회원
          </div>
        </div>
        <div className="flex">
          <span className="register-form-name">이메일</span>
          <div className="flex flex-col">
            <div className="flex">
              <div className="rounded-[20px] w-[282px]  flex items-center justify-center text-gray80 border h-[34px] border-gray-200">
                {agencyEmail}
              </div>
            </div>
          </div>
        </div>

        <div className="flex mt-[40px]">
          <span className="register-form-name">닉네임</span>
          <div className="flex flex-col">
            <div className="flex">
              <input
                type="text"
                className="rounded-[20px] w-[230px] pl-9 text-gray-400 border h-[34px] border-gray-200"
                placeholder="ex) 식스스탭댄스"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex mt-[40px]">
          <span className="register-form-name w-[140px]">사업자 등록번호</span>
          <div className="flex flex-col">
            <div className="flex">
              <input
                type="text"
                className="rounded-[20px] w-[282px] pl-9 text-gray80 border h-[34px] border-gray-200"
                placeholder="ex ) 123-45-67891"
                value={businessLicenseNum}
                onChange={handleBusinessLicenseNumChange}
              />
            </div>
          </div>
        </div>

        <div className="flex mt-[60px]">
          <span className="register-form-name w-[140px]">기관분류</span>
          <div className="flex flex-col">
            <div className="flex">
              <select
                className="rounded-[20px] w-[230px]  pl-9 text-gray80 border h-[37px] border-gray-200"
                placeholder="기관 종류"
                onChange={(e: any) => {
                  setAgencyType(e.target.value);
                }}
              >
                <option defaultChecked value="RIGHTS_GROUP">
                  동물보호센터
                </option>
                <option value="FEED_COMPANY">사료업체</option>
              </select>
            </div>
          </div>
        </div>

        <div className="flex mt-[60px]">
          <span className="register-form-name w-[140px]">기본주소</span>
          <div className="flex flex-col">
            <div className="flex">
              <input
                type="text"
                defaultValue={address}
                className="rounded-[60px] w-[300px] text-center bg-gray40 text-gray-400 border h-[70px] border-gray-200"
              />
              <button
                type="button"
                className="bg-brand100 ml-[50px] text-white rounded-[20px] px-3 h-[30px]"
                onClick={() => {
                  setIsActiveSearchAddressPopUp(!isActiveSearchAddressPopUp);
                }}
              >
                주소 찾기
              </button>
            </div>
          </div>
        </div>

        <div className="flex mt-[40px]">
          <span className="register-form-name">프로필</span>
          <div className="flex flex-col">
            <div className="flex">
              {imageUrl !== "" ? (
                <img
                  src={imageUrl}
                  className="sm:w-[400px] sm:h-[400px] relative"
                  alt="선택한 이미지"
                />
              ) : (
                <div className="sm:w-[400px] sm:h-[400px] bg-[#F7F7F7] flex items-center justify-center">
                  이미지를 선택하세요
                </div>
              )}
              <button
                type="button"
                className="bg-brand100 ml-6 text-white rounded-[20px] px-4 h-[33px] flex items-center justify-center'
          "
                onClick={() => fileInputRef.current?.click()}
              >
                <span className="mr-1"> 파일 업로드</span>
                <UploadSVG width={12} height={12} fillColor="white" />
              </button>
              <button
                type="button"
                className="bg-brand100 ml-6 text-white rounded-[20px] px-4 h-[33px] flex items-center justify-center'
              "
                onClick={() => {
                  setIsActivePfpModal(true);
                }}
              >
                <span className="mr-1"> 프로필 사진 고르기</span>
                <UploadSVG width={12} height={12} fillColor="white" />
              </button>
              <input
                type="file"
                className="hidden"
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>

        <div className="w-full flex items-center justify-center">
          <button
            type="button"
            className="bg-brand100 w-[130px] mt-[80px] mb-[130px] text-[22px] text-white rounded-[30px]   px-3  h-[40px]"
            onClick={() => {
              signUp();
            }}
          >
            가입하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default SocialRegisterAgency;
