// 체인아이디 값 주면 네트워크 이름 알려줌
const MatchChainIdAndNetworkName = (chainId: string) => {
  if (chainId == "0xaa36a7") {
    return "SEPOLIA";
  } else if (chainId == "0x152") {
    return "CRONOS";
  } else if (chainId == "0x61") {
    return "BNB";
  } else if (chainId == "0x2019") {
    return "KLAYTN";
  } else {
    return false;
  }
};

export default MatchChainIdAndNetworkName;
