import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TypeRegisterNFT from "../../../../components/UI/nftDetail/nftType/TypeRegisterNFT";
import TypeLifeNFT from "../../../../components/UI/nftDetail/nftType/TypeLifeNFT";
import TypeClinicNFT from "../../../../components/UI/nftDetail/nftType/TypeClinicNFT";
import TypeAdoptNFT from "../../../../components/UI/nftDetail/nftType/TypeAdoptNFT";
import TypeDonationNFT from "../../../../components/UI/nftDetail/nftType/TypeDonationNFT";
import GetDetailInfoAsUserToServer from "../../../../components/Feat/nft/GetDetailInfoAsUserToServer";

const UserMyNftDetailPage = () => {
  const { id } = useParams();
  const nftId: any = id;
  const [detailData, setDetailData]: any = useState([]);
  const [nftType, setNftType] = useState("");

  useEffect(() => {
    if (sessionStorage.getItem("login_user")) {
      const userStatusString: any = sessionStorage.getItem("login_user");
      const userStatus = JSON.parse(userStatusString);
      const role = userStatus.role;
      if (role == "ROLE_AGENCY") {
        alert("해당 페이지에 권한이 없습니다.");
        window.location.href = "/";
      }
      try {
        const res = GetDetailInfoAsUserToServer(nftId);
        res.then((item) => {
          setDetailData(item);
          setNftType(item.nftType);
        });
      } catch (error) {
        // console.log("error", error);
      }
    } else {
      alert("잘못된 접근 경로입니다.");
      window.location.href = "/";
    }
  }, []);

  return (
    <div className="flex flex-col items-center my-[100px] relative ">
      {nftType == "LIFESTYLE" && <TypeLifeNFT data={detailData} />}
      {nftType == "REGISTRATION" && <TypeRegisterNFT data={detailData} />}
      {nftType == "MEDICAL" && <TypeClinicNFT data={detailData} />}
      {nftType == "ADOPTION" && <TypeAdoptNFT data={detailData} />}
      {nftType == "DONATION" && <TypeDonationNFT data={detailData} />}
    </div>
  );
};

export default UserMyNftDetailPage;
