import React, { useEffect, useState } from "react";
import HeartSVG from "../../../../common/svg/HeartSVG";
import EyeSVG from "../../../../common/svg/EyeSVG";
import instance from "../../../Feat/axios/instance";
import FullHeartSVG from "../../../../common/svg/FullHeartSVG";
import WarningToastify from "../../common/WarningToastify";
import MappingCurrencyType from "../../../Feat/common/MappingCurrencyType";

const RelatedNftCard = (data: any) => {
  const { item } = data;
  const { setDetectUpdate } = data;
  const { detectUpdate } = data;
  const [likeStatus, setLikeStatus] = useState(false);
  const [likeNum, setLikeNum] = useState(0);
  const [currencyType, setCurrencyType] = useState<string | undefined>("");

  const LikeButtonHandler = async () => {
    if (sessionStorage.getItem("login_user")) {
      setDetectUpdate(!detectUpdate);
      try {
        if (likeStatus == true) {
          setLikeStatus(false);
          const url =
            process.env.REACT_APP_SERVER_API + "/nfts/dislike/" + item.nftId;
          const res = await instance.delete(url);
          if (res.status == 200) {
            setLikeNum(likeNum - 1);
          }
        } else {
          setLikeStatus(true);
          const url =
            process.env.REACT_APP_SERVER_API + "/nfts/like/" + item.nftId;
          const res = await instance.post(url);
          if (res.status == 200) {
            setLikeNum(likeNum + 1);
          }
        }
      } catch (error: any) {
        if (error.response.data.message) {
          WarningToastify(error.response.data.message);
          return;
        } else {
          alert("알 수 없는 에러가 발생했습니다.");
        }
      }
    } else {
      WarningToastify("로그인을 먼저 진행해주세요");
      return;
    }
  };

  useEffect(() => {
    setLikeNum(item.likeCount);
    setLikeStatus(item.userLikesNft);
    const currencyResult: string | undefined = MappingCurrencyType(
      item.network
    );
    setCurrencyType(currencyResult);
  }, [data]);
  return (
    <div className="relative sm:max-w-[280px] max-w-[120px]  w-full h-full flex flex-col rounded-lg">
      <img
        src={item.image}
        className="w-full sm:max-w-[240px] sm:max-h-[240px] max-w-[120px] max-h-[120px] rounded-lg object-fill aspect-square"
        onClick={() => {
          window.location.href = `/nftDetail/${item.nftId}`;
        }}
      />
      <div className=" flex flex-col w-full px-1">
        <div className="w-full flex sm:flex-row flex-col ontent-between justify-between items-center mt-[20px]">
          <div className="sm:text-[20px] font-semibold bg-brand100 rounded-[20px] text-white sm:w-[150px] w-[120px] h-[36px] flex items-center justify-center">
            {item?.name}
          </div>
          <div className="flex space-x-[12px]">
            <div
              className="flex flex-col items-center"
              onClick={() => {
                LikeButtonHandler();
              }}
            >
              {likeStatus ? (
                <FullHeartSVG width={22} height={22} fillColor="#e06612" />
              ) : (
                <HeartSVG width={22} height={22} fillColor="#e06612" />
              )}
              <span className="text-[12px] text-brand100">{likeNum}</span>
            </div>
            <div className="flex flex-col items-center">
              <EyeSVG fillColor="#e06612" width={22} height={22} />
              <span className="text-[12px] text-brand100">
                {item.viewCount}
              </span>
            </div>
          </div>
        </div>
        <div className="flex sm:flex-row flex-col justify-center items-center w-full mt-[10px]">
          <span className="text-[#390000]">Price</span>
          <h3 className="sm:text-[16px] text-brand100 sm:ml-6 font-semibold text-[10px]">
            {item?.price} {currencyType}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default RelatedNftCard;
