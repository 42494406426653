import React from "react";
import LifeRegisterNFT from "../../../components/UI/nftUpload/life/LifeRegisterNFT";

const LifeNFTPage = () => {
  return (
    <div>
      <LifeRegisterNFT />
    </div>
  );
};

export default LifeNFTPage;
