import axios from "axios";
import React, { useEffect, useState } from "react";
import instance from "../../../components/Feat/axios/instance";
import UpdateWalletAddressList from "../../../components/Feat/wallet/UpdateWalletAddressList";
import RemoveLoginStatusInSession from "../../../components/Feat/auth/RemoveLoginStatusInSession";

const RedirectLoginPayment = () => {
  const [loginStatus, setLoginStatus] = useState(false);
  const [walletList, setWalletList] = useState([]);
  const [accessTokenState, setAccessTokenState] = useState("");

  const requestSocialLogin = async (code: any) => {
    const redirectURL =
      window.location.protocol + "//" + window.location.host + "/login/social";
    const url =
      process.env.REACT_APP_SERVER_API +
      "/auth/login/kakao?code=" +
      code +
      "&redirectUri=" +
      redirectURL;

    try {
      const response = await axios.get(url);
      if (response.data.status === 200) {
        const accessToken = response.data.data.accessToken;
        const role = response.data.data.role;

        if (role == "ROLE_USER") {
          const user = {
            loginAddress: "",
            role: role,
            accessToken: accessToken,
            walletType: "",
            type: response.data.data.type,
          };
          sessionStorage.setItem("login_user", JSON.stringify(user));
          const url = process.env.REACT_APP_SERVER_API + "/users/profile";
          const userInfoRes = await instance.get(url);
          if (userInfoRes.status == 200) {
            setWalletList(userInfoRes.data.data.walletList);
            setAccessTokenState(accessToken);
            const walletList = userInfoRes.data.data.walletList;
            const walletAddress = walletList[0].walletAddress.split("::");
            const user = {
              loginAddress: walletAddress[0],
              privateKey: walletAddress[1],
              role: role,
              accessToken: accessToken,
              walletType: "WHATCON",
              type: response.data.data.type,
            };
            sessionStorage.setItem("login_user", JSON.stringify(user));
            //
          }
          await UpdateWalletAddressList(role);
          window.location.href = "/";
        } else if (role == "ROLE_AGENCY") {
          const user = {
            loginAddress: "",

            role: role,
            accessToken: accessToken,
            walletType: "",
            type: response.data.data.type,
          };
          sessionStorage.setItem("login_user", JSON.stringify(user));
          const url = process.env.REACT_APP_SERVER_API + "/agency/profile";
          const userInfoRes = await instance.get(url);
          if (userInfoRes.status == 200) {
            setWalletList(userInfoRes.data.data.walletList);
            setAccessTokenState(accessToken);
            const walletList = userInfoRes.data.data.walletList;
            const walletAddress = walletList[0].walletAddress.split("::");
            const user = {
              loginAddress: walletAddress[0],
              privateKey: walletAddress[1],

              role: role,
              accessToken: accessToken,
              walletType: "WHATCON",
              type: response.data.data.type,
            };
            sessionStorage.setItem("login_user", JSON.stringify(user));
            //
          }
          await UpdateWalletAddressList(role);
          window.location.href = "/";
        }
      }
    } catch (error: any) {
      if (error.response?.status == 404) {
        alert("회원 가입되지 않은 계정입니다.");
        window.location.href = "/";
        return;
      } else if (error.response?.status == 400) {
        alert("잘못된 요청입니다. ");
        RemoveLoginStatusInSession();
        window.location.href = "/";
      } else {
        alert("로그인에 실패했습니다.");
      }
      return false;
    }
  };

  useEffect(() => {
    const code = new URL(window.location.href).searchParams.get("code");
    requestSocialLogin(code);
  }, []);

  return (
    <div className="flex items-center justify-center w-full min-h-screen"></div>
  );
};

export default RedirectLoginPayment;
