const MatchChainIdAndAuction = (currentNetwork: string) => {
  if (currentNetwork == "0xaa36a7") {
    return process.env.REACT_APP_BUY_AUCTION_SEPOLIA_CONTRACT_ADDRESS;
  } else if (currentNetwork == "0x152") {
    return process.env.REACT_APP_BUY_AUCTION_CRONOS_CONTRACT_ADDRESS;
  } else if (currentNetwork == "0x2019") {
    return process.env.REACT_APP_BUY_AUCTION_BAOBAB_CONTRACT_ADDRESS;
  } else if (currentNetwork == "0x61") {
    return process.env.REACT_APP_BUY_AUCTION_BNB_CONTRACT_ADDRESS;
  }
};
export default MatchChainIdAndAuction;
