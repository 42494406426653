import React from "react";

const EyeSVG: React.FC<SvgProps> = ({ fillColor, width, height }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 27.332 20.423"
    >
      <g
        id="Icon_feather-eye"
        data-name="Icon feather-eye"
        transform="translate(-0.5 -5)"
      >
        <path
          id="패스_218"
          data-name="패스 218"
          d="M1.5,15.212S6.106,6,14.166,6s12.666,9.212,12.666,9.212-4.606,9.212-12.666,9.212S1.5,15.212,1.5,15.212Z"
          fill="none"
          stroke={fillColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path
          id="패스_219"
          data-name="패스 219"
          d="M20.409,16.954A3.454,3.454,0,1,1,16.954,13.5,3.454,3.454,0,0,1,20.409,16.954Z"
          transform="translate(-2.788 -1.743)"
          fill="none"
          stroke={fillColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
};

export default EyeSVG;
