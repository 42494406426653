import { Carousel } from "flowbite-react";
import GetBuyingNFTList from "../../../Feat/nft/GetBuyingNFTList";
import { useEffect, useState } from "react";

const EmergencyIntroSlide = () => {
  const [emergencyList, setEmergencyList] = useState([]);
  const requestEmergencyNftList = async () => {
    const response = await GetBuyingNFTList({
      keyword: "",
      network: "ALL",
      nftType: "ALL",
      sortBy: "ENDING_SOON",
      page: 0,
      size: 12,
    });
    setEmergencyList(response?.data.data.content);
  };
  const customTheme = {
    root: {
      leftControl:
        "absolute top-[170px] left-[-60px] hidden sm:flex items-center justify-center px-4 focus:outline-none  main-left-controller_emergency",
      rightControl:
        "absolute top-[170px] right-[-60px] hidden sm:flex items-center justify-center px-4 focus:outline-none main-right-controller_emergency",
    },
  };

  const imageGroups: any = Array.from(
    { length: Math.ceil(emergencyList.length / 4) },
    (_, groupIndex) => emergencyList.slice(groupIndex * 4, (groupIndex + 1) * 4)
  );

  useEffect(() => {
    requestEmergencyNftList();
  }, []);

  return (
    <div className="md:flex items-center sm:h-[380px] h-[160px] justify-center sm:w-[90%] ">
      <Carousel theme={customTheme} className="sm:w-[90%] ">
        {imageGroups.map((imageGroup: any, groupIndex: any) => (
          <div
            className="flex items-center justify-center space-x-3 h-full"
            key={groupIndex}
          >
            {imageGroup.map((imageInfo: any, index: number) => (
              <div className="relative" key={index}>
                <img
                  onClick={() => {
                    window.location.href = "/nftDetail/" + imageInfo.nftId;
                  }}
                  src={imageInfo.image}
                  className="object-cover aspect-square max-h-[80px] sm:max-h-[320px] w-full h-full rounded-lg"
                />
              </div>
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default EmergencyIntroSlide;
