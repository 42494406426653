import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import UserMyNftDetailCard from "./nftDetail/UserMyNftDetailCard";
import { useNavigate } from "react-router-dom";
import CheckCurrentNetwork from "../../../Feat/network/CheckCurrentNetwork";
import MatchChainIdAndNetworkName from "../../../Feat/network/MatchChainIdAndNetworkName";
import instance from "../../../Feat/axios/instance";
import CheckCurrentKlaytnNetwork from "../../../Feat/wallet/klaytn/CheckCurrentKlaytnNetwork";
import KaikasMatchChainIdAndNetworkName from "../../../Feat/network/klaytn/KaikasMatchChainIdAndNetworkName";
import GetLoginUserInfo from "../../../Feat/user/GetLoginUserInfo";
import WarningToastify from "../../common/WarningToastify";
import RemoveLoginStatusInSession from "../../../Feat/auth/RemoveLoginStatusInSession";

const UserMyPageContent = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0); // 페이지 번호를 0부터 시작하도록 변경
  const itemsPerPage = 12;
  const handlePageChange = (page: number) => {
    setPage(page - 1); // 페이지 번호를 서버에 맞게 0부터 시작하도록 변경
  };
  const [userWallet, setUserWallet] = useState("");
  const [networkNum, setNetworkNum] = useState("");
  const [networkName, setNetworkName] = useState("");
  const [myDataList, setMyDataList] = useState<ItemType[]>([]);
  const [userInfo, setUserInfo] = useState([]);
  const [checkStatus, setCheckStatus] = useState("ALL");

  const updateUserData = async () => {
    const userStatusString: any = sessionStorage.getItem("login_user");
    const userStatus = JSON.parse(userStatusString);
    setUserWallet(userStatus.loginAddress);

    if (userStatus.walletType == "METAMASK") {
      const currentNetwork: any = await CheckCurrentNetwork();
      const currentNetworkName: any =
        MatchChainIdAndNetworkName(currentNetwork);
      if (!currentNetworkName) {
        setNetworkName("제공하는 서비스 네트워크가 아닙니다.");
        return;
      }
      setNetworkName(currentNetworkName);
    } else if (userStatus.walletType == "KAIKAS") {
      const res: any = await CheckCurrentKlaytnNetwork();
      const currentNetworkName: any = KaikasMatchChainIdAndNetworkName(
        res.chainId
      );
      setNetworkName(currentNetworkName);
    } else if (userStatus.walletType == "WHATCON") {
      setNetworkName("왓콘 체인");
    }
  };

  const getUserNFTList = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_API +
        `/users/nfts?status=${checkStatus}&page=${page}&size=${itemsPerPage}`;

      const response = await instance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      });

      if (response.data) {
        setMyDataList(response.data.data.content);
      } else {
        setMyDataList([]);
      }
    } catch (error: any) {
      if (error.response.data.message) {
        WarningToastify(error.response.data.message);
        return;
      } else {
        alert("알 수 없는 에러가 발생했습니다.");
      }
      return;
    }
  };

  const getLoginInfo = async () => {
    const res: any = await GetLoginUserInfo();
    if (res.status) {
      setUserInfo(res.data);
    } else if (!res.status) {
      WarningToastify(res.message);
      RemoveLoginStatusInSession();
      window.location.href = "/";
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("login_user")) {
      updateUserData();
      getUserNFTList();
      getLoginInfo();
    } else {
      alert("로그인을 먼저 진행해주세요.");
      window.location.href = "/";
      return;
    }
  }, [page, checkStatus]);

  return (
    <div className="flex items-center justify-center ">
      <div className="flex flex-col  mt-[80px]  max-w-[calc(1280px+60px)] ">
        <div className="flex sm:content-between sm:justify-between w-full ">
          <div className="flex flex-col">
            <h3 className="text-brand100 text-[32px] font-semibold">MY PAGE</h3>
            <h3 className="text-gray100 text-[20px] ">마이페이지</h3>
          </div>
          <button
            type="button"
            onClick={() => {
              navigate("/user/mypage/edit");
            }}
            className="mypage-mydata-edit-button"
          >
            내정보 수정
          </button>
        </div>
        <div className="flex mt-[70px] sm:flex-row flex-col">
          <div className="sm:flex hidden flex-col  ">
            <h3 className="text-brand100 font-bold">나의 지갑주소 </h3>
            <h3 className="text-gray100 mt-2">{userWallet}</h3>
          </div>

          <div className="mypage-connect-network ">
            <h3 className="text-brand100  font-bold">연결된 네트워크 </h3>
            <h3 className="text-gray100 mt-2">{networkNum}</h3>
            <h3 className="text-gray100 mt-1">{networkName}</h3>
          </div>
        </div>
        <div className="mt-10">
          <input
            type="checkbox"
            className="rounded-full mr-2 border-brand100"
            onChange={() => {
              if (checkStatus == "ALL") {
                setCheckStatus("LIKE");
              } else {
                setCheckStatus("ALL");
              }
            }}
          />
          <span className="text-brand100"> 내가 좋아요한 리스트</span>
        </div>
        {myDataList.length == 0 && (
          <div className=" w-full min-h-[500px] flex items-center justify-center lg:min-w-[1120px] md:min-w-[860px] sm:min-w-[680px] gap-7  mb-[80px]">
            <span className="text-brand100 text-xl">
              * 나의 NFT가 없습니다.
            </span>
          </div>
        )}

        {myDataList.length !== 0 && (
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4  lg:min-w-[1120px] md:min-w-[860px] sm:min-w-[680px] gap-7 min-h-screen mb-[80px]">
            {myDataList.map((item, index) => (
              <div
                className="mb-4 h-fit transform transition-transform hover:scale-105 "
                key={index}
              >
                <UserMyNftDetailCard item={item} />
              </div>
            ))}
          </div>
        )}

        {/* 페이지네이션 */}
        <div className="flex w-full items-center justify-center">
          {/* Pagination 컴포넌트에 tailwind CSS 클래스 추가 */}
          <Pagination
            activePage={page}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={myDataList.length}
            pageRangeDisplayed={12}
            onChange={handlePageChange}
            itemClass="inline-block mx-1 text-[22px]  font-semibold  px-2 py-1 rounded-lg cursor-pointer mb-[80px]"
            activeClass=" text-brand100 text-[22px]  font-semibold"
            firstPageText={<div className="text-brand100">&#171;</div>}
            lastPageText={<div className="text-brand100">&#187;</div>}
          />
        </div>
      </div>
    </div>
  );
};

export default UserMyPageContent;
