import { useRecoilState } from "recoil";
import six_steps_logo from "../../../../common/images/6_steps.jpeg";
import { signUpUserState } from "../../../../utils/recoil/auth/register/atom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NotiToastify from "../../common/NotiToastify";

const RegisterAgree = () => {
  const [adAgreed, setAdAgreed] = useState(false);
  const [signUpUser, setSignUpUser] = useRecoilState(signUpUserState);
  const [requiredCheck1, setRequiredCheck1] = useState(false);
  const [requiredCheck2, setRequiredCheck2] = useState(false);
  const navigate = useNavigate();

  const handleAdAgreementChange = (checked: any) => {
    setAdAgreed(checked);

    // Update the signUpUserState's adAgreed property.
    setSignUpUser((prevSignUpUser) => ({
      ...prevSignUpUser,
      adAgreed: checked,
    }));
  };

  const handleConfirmClick = () => {
    if (!requiredCheck1 || !requiredCheck2) {
      NotiToastify("필수 동의란에 체크해 주세요!");
      return;
    } else {
      navigate("/register/main");
    }
  };

  const handleCancelClick = () => {
    window.history.back();
  };

  useEffect(() => {
    if (sessionStorage.getItem("login_user")) {
      alert("이미 로그인된 상태입니다.");
      window.location.href = "/";
    }
  }, []);

  return (
    <div className="flex items-center justify-center ">
      <div className="flex items-center justify-center flex-col mt-[50px] w-[80%] ">
        <div className="w-ful items-center justify-center flex flex-col">
          <img src={six_steps_logo} className="w-[120px] h-[105px]" />
          <h3 className="text-gray100 mt-[20px]  text-[20px]">
            회원 약관 동의
          </h3>
        </div>

        {/* 이용약관 동의(필수) 1 */}
        <div className="flex flex-col mt-[100px]">
          <div className="flex content-between justify-between">
            <h3 className="text-[22px] text-brand100 mb-3">
              이용약관 동의(필수)
            </h3>
            <div>
              <div className="flex items-center text-brand100">
                <input
                  type="checkbox"
                  checked={requiredCheck1}
                  onChange={() => setRequiredCheck1(!requiredCheck1)}
                  className="mr-2 border border-brand100 rounded-full checked:bg-brand100"
                />
                <h3 className="mr-2 text-[18px]">동의함 </h3>

                <input
                  type="checkbox"
                  checked={!requiredCheck1}
                  onChange={() => setRequiredCheck1(!requiredCheck1)}
                  className="mr-1 ml-2 border border-brand100 rounded-full checked:bg-brand100"
                />
                <h3 className="text-brand100 text-[18px] ">동의하지 않음</h3>
              </div>
            </div>
          </div>
          <div className="border border-brand100 w-full  h-[200px] rounded-[10px] text-gray80 p-5 overflow-auto">
            제 1 장 총 칙 <br />
            <br />제 1 조 (목적) <br /> <br />
            이 약관은 (주)왓콘(이하 "사이트"라 합니다)에서 제공하는 6 STEPS(이하
            "서비스"라 합니다)의 이용 조건 및 절차에 관한 기본적인 사항을
            규정함을 목적으로 합니다.
            <br /> <br />
            제 2 조 (약관의 효력 및 변경) <br />
            <br />
            ① 이 약관은 서비스 화면이나 기타의 방법으로 이용고객에게
            공지함으로써 효력을 발생합니다. <br />
            ② 사이트는 이 약관의 내용을 변경할 수 있으며, 변경된 약관은 제1항과
            같은 방법으로 공지 또는 통지함으로써 효력을 발생합니다. <br />
            <br /> <br />
            제 3 조 (용어의 정의) <br />
            이 약관에서 사용하는 용어의 정의는 다음과 같습니다. <br />
            <br />
            ① 회원 : 사이트와 서비스 이용계약을 체결하거나 이용자 아이디(ID)를
            부여받은 개인 또는 단체를 말합니다. <br />
            ② 신청자 : 회원가입을 신청하는 개인 또는 단체를 말합니다. <br />
            ③ 지갑주소 : 블록체인 네트워크 상 로그인 및 연결한 지갑주소를
            의미합니다. <br />
            ④ 해지 : 사이트 또는 회원이 서비스 이용계약을 취소하는 것을
            말합니다. <br />
            <br /> <br />
            제 2 장 서비스 이용계약 <br />
            <br />
            <br />
            제 4 조 (이용계약의 성립) <br />
            ① 이용약관 하단의 동의 버튼을 누르면 이 약관에 동의하는 것으로
            간주됩니다. <br />
            ② 이용계약은 서비스 이용희망자의 이용약관 동의 후 이용 신청에 대하여
            사이트가 승낙함으로써 성립합니다. <br />
            <br /> <br />
            제 5 조 (이용신청) <br />
            ① 신청자가 본 서비스를 이용하기 위해서는 사이트 소정의 가입신청
            양식에서 요구하는 이용자 정보를 기록하여 제출해야 합니다. <br />②
            가입신청 양식에 기재하는 모든 이용자 정보는 모두 실제 데이터인
            것으로 간주됩니다. 실명이나 실제 정보를 입력하지 않은 사용자는
            법적인 보호를 받을 수 없으며, 서비스의 제한을 받을 수 있습니다.{" "}
            <br />
            <br />
            <br />
            제 6 조 (이용신청의 승낙) <br />
            ① 사이트는 신청자에 대하여 제2항, 제3항의 경우를 예외로 하여 서비스
            이용신청을 승낙합니다. <br />
            ② 사이트는 다음에 해당하는 경우에 그 신청에 대한 승낙 제한사유가
            해소될 때까지 승낙을 유보할 수 있습니다. <br />
            <br />
            가. 서비스 관련 설비에 여유가 없는 경우 <br />
            나. 기술상 지장이 있는 경우 <br />
            다. 기타 사이트가 필요하다고 인정되는 경우 <br />
            ③ 사이트는 신청자가 다음에 해당하는 경우에는 승낙을 거부할 수
            있습니다. <br />
            가. 다른 개인(사이트)의 명의를 사용하여 신청한 경우 <br />
            나. 이용자 정보를 허위로 기재하여 신청한 경우 <br />
            다. 사회의 안녕질서 또는 미풍양속을 저해할 목적으로 신청한 경우{" "}
            <br />
            라. 기타 사이트 소정의 이용신청요건을 충족하지 못하는 경우 <br />
            <br />
            <br />
            제 7 조 (이용자정보의 변경) <br />
            회원은 이용 신청시에 기재했던 회원정보가 변경되었을 경우에는,
            온라인으로 수정하여야 하며 변경하지 않음으로 인하여 발생되는 모든
            문제의 책임은 회원에게 있습니다. <br />
            <br />
            <br />
            제 3 장 계약 당사자의 의무 <br />
            <br />
            제 8 조 (사이트의 의무) <br /> <br />
            ① 사이트는 회원에게 각 호의 서비스를 제공합니다. <br />
            가. 신규서비스와 도메인 정보에 대한 뉴스레터 발송 <br />
            나. 추가 도메인 등록시 개인정보 자동 입력 <br />
            다. 도메인 등록, 관리를 위한 각종 부가서비스 <br />
            ② 사이트는 서비스 제공과 관련하여 취득한 회원의 개인정보를 회원의
            동의없이 타인에게 누설, 공개 또는 배포할 수 없으며, 서비스관련 업무
            이외의 상업적 목적으로 사용할 수 없습니다. 단, 다음 각 호의 1에
            해당하는 경우는 예외입니다. <br />
            가. 전기통신기본법 등 법률의 규정에 의해 국가기관의 요구가 있는 경우{" "}
            <br />
            나. 범죄에 대한 수사상의 목적이 있거나 정보통신윤리 위원회의 요청이
            있는 경우 <br />
            다. 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우 <br />
            ③ 사이트는 이 약관에서 정한 바에 따라 지속적, 안정적으로 서비스를
            제공할 의무가 있습니다. <br />
            <br />
            <br />
            제 9 조 (회원의 의무) <br />
            <br /> <br />① 회원은 서비스 이용 시 다음 각 호의 행위를 하지 않아야
            합니다. <br />
            가. 다른 회원 계정을 부정하게 사용하는 행위 <br />
            나. 서비스에서 얻은 정보를 사이트의 사전승낙 없이 회원의 이용 이외의
            목적으로 복제하거나 이를 변경, 출판 및 방송 등에 사용하거나 타인에게
            제공하는 행위 <br />
            다. 사이트의 저작권, 타인의 저작권 등 기타 권리를 침해하는 행위{" "}
            <br />
            라. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등을
            타인에게 유포하는 행위 <br />
            마. 범죄와 결부된다고 객관적으로 판단되는 행위 <br />
            바. 기타 관계법령에 위배되는 행위 <br />
            ② 회원은 관계법령, 이 약관에서 규정하는 사항, 서비스 이용 안내 및
            주의 사항을 준수하여야 합니다. <br />
            ③ 회원은 내용별로 사이트가 서비스 공지사항에 게시하거나 별도로
            공지한 이용 제한 사항을 준수하여야 합니다. <br />
            <br /> <br />
            제 4 장 서비스 제공 및 이용 <br />
            <br />
            제 10 조 (회원 정보 관리에 대한 회원의 의무) <br />
            ① 회원 정보와 로그인 정보에 대한 모든 관리는 회원에게 책임이
            있습니다. 회원에게 부여된 로그인 정보의 관리소홀, 부정사용에 의하여
            발생하는 모든 결과에 대한 전적인 책임은 회원에게 있습니다. <br />
            ② 자신의 계정이 부정하게 사용된 경우 또는 기타 보안 위반에 대하여,
            회원은 반드시 사이트에 그 사실을 통보해야 합니다. <br />
            <br />
            <br />
            제 11 조 (서비스 제한 및 정지)
            <br />
            ① 사이트는 전시, 사변, 천재지변 또는 이에 준하는 국가비상사태가
            발생하거나 발생할 우려가 있는 경우와 전기통신사업법에 의한 기간통신
            사업자가 전기통신서비스를 중지하는 등 기타 불가항력적 사유가 있는
            경우에는 서비스의 전부 또는 일부를 제한하거나 정지할 수 있습니다.
            <br />
            ② 사이트는 제1항의 규정에 의하여 서비스의 이용을 제한하거나 정지할
            때에는 그 사유 및 제한기간 등을 지체없이 회원에게 알려야 합니다.
            <br />
            <br />
            제5장 계약사항의 변경, 해지
            <br />
            <br />
            <br />
            제 12 조 (정보의 변경)
            <br />
            회원이 고객정보를 변경하고자 하는 경우에는 홈페이지의 회원정보 변경
            서비스를 이용하여 변경할 수 있습니다.
            <br />
            <br />
            제 13 조 (계약사항의 해지)
            <br />
            <br />
            <br />
            회원은 서비스 이용계약을 해지할 수 있으며, 해지할 경우에는 본인이
            직접 서비스를 통하거나 전화 또는 온라인 등으로 사이트에 해지신청을
            하여야 합니다. 사이트는 해지신청이 접수된 당일부터 해당 회원의
            서비스 이용을 제한합니다. 사이트는 회원이 다음 각 항의 1에 해당하여
            이용계약을 해지하고자 할 경우에는 해지조치 7일전까지 그 뜻을
            이용고객에게 통지하여 소명할 기회를 주어야 합니다.
            <br />
            ① 이용고객이 이용제한 규정을 위반하거나 그 이용제한 기간 내에 제한
            사유를 해소하지 않는 경우
            <br />
            ② 정보통신윤리위원회가 이용해지를 요구한 경우
            <br />
            ③ 이용고객이 정당한 사유 없이 의견진술에 응하지 아니한 경우
            <br />
            ④ 타인 명의로 신청을 하였거나 신청서 내용의 허위 기재 또는
            허위서류를 첨부하여 이용계약을 체결한 경우
            <br />
            사이트는 상기 규정에 의하여 해지된 이용고객에 대해서는 별도로 정한
            기간동안 가입을 제한할 수 있습니다.
            <br />
            <br />
            <br />
            제6장 손해배상
            <br />
            <br />
            제 14 조 (면책조항)
            <br />
            ① 사이트는 회원이 서비스 제공으로부터 기대되는 이익을 얻지
            못하였거나 서비스 자료에 대한 취사선택 또는 이용으로 발생하는 손해
            등에 대해서는 책임이 면제됩니다.
            <br />
            ② 사이트는 회원의 귀책사유나 제3자의 고의로 인하여 서비스에 장애가
            발생하거나 회원의 데이터가 훼손된 경우에 책임이 면제됩니다.
            <br />
            ③ 사이트는 회원이 게시 또는 전송한 자료의 내용에 대해서는 책임이
            면제됩니다.
            <br />
            ④ 상표권이 있는 도메인의 경우, 이로 인해 발생할 수도 있는 손해나
            배상에 대한 책임은 구매한 회원 당사자에게 있으며, 사이트는 이에 대한
            일체의 책임을 지지 않습니다.
            <br />
            <br />
            <br />
            제 15 조 (관할법원)
            <br />
            <br />
            서비스와 관련하여 사이트와 회원간에 분쟁이 발생할 경우 사이트의 본사
            소재지를 관할하는 법원을 관할법원으로 합니다.
            <br />
          </div>
        </div>

        {/* 이용약관 동의(필수) 2 */}
        <div className="flex flex-col ">
          <div className="flex content-between justify-between mt-[50px]">
            <h3 className="text-[22px] text-brand100 mb-3 ">
              개인정보 수집 및 이용 동의(필수)
            </h3>
            <div>
              <div className="flex items-center justify-center text-brand100">
                <input
                  type="checkbox"
                  checked={requiredCheck2}
                  onChange={() => setRequiredCheck2(!requiredCheck2)}
                  className="mr-2 border border-brand100 rounded-full checked:bg-brand100"
                />
                <h3 className="mr-1 text-[18px]">동의함 </h3>

                <input
                  type="checkbox"
                  checked={!requiredCheck2}
                  onChange={() => setRequiredCheck2(!requiredCheck2)}
                  className="mr-1 ml-2 border border-brand100 rounded-full checked:bg-brand100"
                />
                <h3 className="text-brand100 text-[18px]">동의하지 않음</h3>
              </div>
            </div>
          </div>
          <div className="border border-brand100 w-full h-[200px] rounded-[10px] text-gray80 p-5 overflow-auto">
            * 개인정보 수집 및 이용에 대한 동의
            <br />
            (가) 회원가입
            <br />
            <br />
            1. 수집되는 개인정보 항목 <br />
            - 회원으로 처음 가입할 때, 회사는 원활한 고객 상담 및 서비스 제공을
            위해 다음과 같은 최소한의 개인정보를 수집합니다.
            <br />
            ① 개인 회원: 이메일 주소, 지갑 주소 <br />
            ② 기업 회원: 사업자 등록 번호, 지갑 주소, 기관명, 기본 주소
            <br />
            <br />
            (나)서비스 이용
            <br />
            - 회사는 서비스 제공을 위해 다음과 같은 정보를 수집할 수 있습니다.
            <br />
            (1) 기본 정보 <br />
            <br />
            ① 기관으로부터 받은 동물 정보 <br />
            - 이름, 구조일자, 나이, 성별, 중성화 여부, 보호기관명, 품종,
            구조장소
            <br />
            (2) 민감 정보
            <br />
            ① 병원으로부터 받은 동물 진료내역 정보 <br />
            - 진료명, 진료 일자, 진료 내용, 특이사항, 진료 기관명 <br />
            ② 기관으로부터 받은 동물 정보 <br />
            - 생활, 이상행동 여부, 구조 사유, 성격(양육일기) <br />
            <br />
            (다) 결제: 유료 서비스 이용 및 구매에 대한 결제 <br />
            <br />
            ① 개인 정보: 이름, 이메일, 생년월일 <br />
            ② 결제 정보: <br />
            - 블록체인 결제 : 지갑 주소, 트랜잭션 해쉬 등 거래 진행을 위해
            필요한 기타 발생 데이터 <br />
            - 카드 결제: 마스킹된 카드 정보, 만료일, 비밀번호 첫 두 자리 <br />
            - 가상 계좌: 가상 계좌 번호, 전화번호, 이메일 주소 <br />
            <br />
            ③ 요금 정산: 회사명, 담당자 정보 (이름, 연락처, 이메일 주소) <br />
            서비스 이용 정보: IP 주소, 쿠키, 방문 날짜 및 시간, 서비스 이용
            기록, 부적절한 이용 기록, 브라우저 정보, 운영체제 정보 (OS), 기기
            정보, MAC 주소, 방문 날짜 및 시간 등
            <br />
            <br />
            2. 개인정보 처리 목적 <br />
            <br />
            각각의 경우에 회사는 관련 법령에 따라 다음과 같은 목적으로
            개인정보를 처리합니다. <br />
            처리 중인 개인정보는 다음 목적 이외의 용도로 사용되지 않으며, <br />{" "}
            이용 목적이 변경되는 경우 개인정보 보호법 제18조에 따라 별도의
            동의를 얻는 등 필요한 조치를 취합니다.
            <br />
            (1) 회원 관리 <br />
            <br />
            - 개인 식별 및 회원 서비스 제공 <br />
            - 가입 의사 확인 및 회원 가입 <br />
            - 이용 약관을 위반한 회원의 이용 제한 및 서비스 부정 이용에 대한
            제재 <br />
            - 분쟁 해결을 위한 민원 처리 및 기록 보존 <br />
            - 회원 탈퇴 의사 확인 <br />
            - 무료 체험 서비스 제공 시 사용자 식별 및 인증 <br />
            - 유료 서비스로 전환 후의 고객 관리 등 <br />
            - 다양한 제품 정보, 소개 조회 등 <br />
            - 신규 서비스 개발 및 서비스 개선 <br />
            - 통계적 특성에 따른 서비스 제공 및 광고 게시 <br />
            - 서비스의 유효성 확인 <br />
            - 접속 빈도 확인 <br />
            - 회원의 서비스 이용에 대한 통계 분석 등 <br />
            <br />
            (2) 유료 서비스 제공 및 유료 서비스 이용 요금 정산 <br />
            <br />
            - 콘텐츠 제공 <br />
            - 유료 서비스 이용, 구매 및 결제에 대한 청구, 신원 확인, 상품 발송
            또는 청구서 송부, 요금 청구 등 <br />
            <br />
            (3) 법적 증거로의 사용 <br />
            - 법적 분쟁 시 증거로 제출 <br />
            <br />
            (4) 위탁자의 요청에 따른 처리 <br />
            - 수탁자의 요청에 따라 서비스 이용 시 개인정보 수집 및 처리 <br />
            <br />
            3. 개인정보 수집 및 이용에 대한 동의 거부권 및 불이익 내용 <br />
            정보주체는 개인정보 수집 및 이용에 동의하지 않을 권리가 있으며,
            동의를 거부할 경우 서비스 이용에 제한이 있을 수 있습니다. <br />
          </div>
        </div>

        {/* 이용약관 동의(선택) */}
        <div className="flex flex-col ">
          <div className="flex content-between justify-between mt-[50px]">
            <h3 className="text-[22px] text-brand100 mb-3">
              프로모션 정보 수신 동의(선택)
            </h3>
            <div>
              <div className="flex items-center text-brand100">
                <input
                  type="checkbox"
                  checked={adAgreed}
                  onChange={(e) => handleAdAgreementChange(e.target.checked)}
                  className="mr-2 border border-brand100 rounded-full checked:bg-brand100"
                />
                <h3 className="mr-1 text-[18px]">동의함 </h3>

                <input
                  type="checkbox"
                  checked={!adAgreed}
                  onChange={(e) => handleAdAgreementChange(!e.target.checked)}
                  className="mr-1 ml-2 border border-brand100 rounded-full checked:bg-brand100"
                />
                <h3 className="text-brand100 text-[18px] ">동의하지 않음</h3>
              </div>
            </div>
          </div>
          <div className="border border-brand100 w-full sm:max-w-[1260px] h-[200px] rounded-[10px] text-gray80 p-5 overflow-auto">
            프로모션 정보 수신 동의 (선택) <br />
            <br />
            6 STEPS에서 제공하는 이벤트/혜택 등 다양한 정보를,
            이메일(전자메일)로 받아보실 수 있습니다. <br />
            <br />
            <br />
            일부 서비스(별도 회원 체계로 운영하거나 후아 가입 이후 추가 가입하여
            이용하는 서비스 등)의 경우, 개별 서비스에 대해 별도 수신 동의를 받을
            수 있으며, 이때에도 수신 동의에 대해 별도로 안내하고 동의를
            받습니다.
          </div>
        </div>
        <div className="flex w-full items-center justify-center space-x-5 mt-[50px] mb-[140px] ">
          <button
            type="button"
            className="border border-brand100 w-[120px]  rounded-[30px] text-brand100 h-[38px] text-[20px]"
            onClick={handleCancelClick}
          >
            취소
          </button>

          <button
            type="button"
            className=" bg-brand100 w-[120px] rounded-[30px] text-white h-[38px] text-[20px]"
            onClick={handleConfirmClick}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegisterAgree;
