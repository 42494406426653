const CheckCurrentNetwork = async () => {
  if (window.ethereum) {
    const chainId = await window.ethereum.request({
      method: "eth_chainId",
    });
    return chainId;
  }
};

export default CheckCurrentNetwork;
