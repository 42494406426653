import instance from "../axios/instance";

const GetDetailInfoUseNFTIdToServerAsLogin = async (nftId: number) => {
  const url =
    process.env.REACT_APP_SERVER_API + "/nfts/info/" + nftId + "?myPage=true";
  try {
    const res = await instance.get(url);
    if (res.status === 200) {
      return res.data.data;
    } else {
      throw new Error("서버 응답 실패");
    }
  } catch (error: any) {
    window.location.href = "/agency/mypage";
    // console.log("error:", error);
    if (error.response.data.message) {
      alert(error.response.data.message);
      return;
    } else {
      alert("알 수 없는 에러가 발생했습니다.");
    }
    return;
  }
};
export default GetDetailInfoUseNFTIdToServerAsLogin;
