import { useEffect, useRef, useState } from "react";
import Modal from "../../common/Modal";
import IsMitingModalContent from "../register/IsMitingModalContent";
import SelectDownArrow from "../../../../common/svg/SelectDownArrow";
import UploadSVG from "../../../../common/svg/UploadSVG";
import KlaytnEtcMinting from "../../../Feat/nft/klaytn/KlaytnEtcMinting";
import MintingEtcNFT from "../../../Feat/nft/MintingEtcNFT";
import WarningToastify from "../../common/WarningToastify";
import instance from "../../../Feat/axios/instance";

const DonationRegisterNFT = () => {
  const [isActiveMinting, setIsActiveModal] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [isAgreed, setIsAgreed] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [petId, setPetId] = useState(1);
  const nftType = "DONATION";
  const currentDate = `${new Date().getFullYear()}-${
    new Date().getMonth() + 1
  }-${new Date().getDate()}`;

  const [mintingData, setMintingData] = useState({
    name: "",
    description: "",
    donationDate: currentDate,
    coupondataId: "",
    image: null,
  });
  const [couponNum, setCouponNum] = useState("");

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile: File | null = e.target.files?.[0] || null;
    setFile(selectedFile);
    setMintingData((prev: any) => ({
      ...prev,
      image: selectedFile,
    }));
  };

  const getCouponList = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_API + "/nfts/coupondata";
      const res = await instance.get(url);
      setMintingData((prev: any) => ({
        ...prev,
        coupondataId: res.data.data.couponDataId,
      }));
      setCouponNum(res.data.data.coupon);
    } catch (error: any) {
      if (error.response.data.message) {
        WarningToastify(error.response.data.message);
        return;
      } else {
        alert("알 수 없는 에러가 발생했습니다.");
      }
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("login_user")) {
      const userStatusString: any = sessionStorage.getItem("login_user");
      const userStatus = JSON.parse(userStatusString);

      if (userStatus.type !== "FEED_COMPANY") {
        WarningToastify("사료업체만 기부 NFT 민팅이 가능합니다.");
        window.location.href = "/nftUpload";
      } else if (userStatus.type == "FEED_COMPANY") {
        getCouponList();
        return;
      }
    } else {
      alert("로그인을 먼저 진행해주세요.");
      window.location.href = "/";
    }
  }, []);

  return (
    <div className="w-full flex flex-col items-center justify-center">
      <Modal
        content={<IsMitingModalContent />}
        isActiveModal={isActiveMinting}
        closeModal={setIsActiveModal}
      />
      <div className="flex flex-col items-center justify-center mt-[40px] mb-[120px]">
        <div className="w-full">
          <div className="nfttype-type-button">기부 NFT</div>
          <h3 className="text-[42px] text-brand100 font-semibold w-full mb-[10px] mt-[5px]">
            동물 NFT 생성
          </h3>
        </div>

        <div className="flex mt-[40px] w-full">
          <div>
            <div className="flex">
              <span className="register-form-name">제목</span>
              <input
                type="text"
                placeholder="제목입력"
                maxLength={8}
                className=" border-gray40  pl-7 text-gray80  h-[42px] w-[240px]   rounded-[30px] "
                onChange={(e: any) => {
                  setMintingData((prev: any) => ({
                    ...prev,
                    name: e.target.value,
                  }));
                }}
              />
            </div>
            <div className="flex mt-[40px] ">
              <span className="register-form-name">설명</span>
              <textarea
                id="description"
                className="p-5 border-gray40  text-[#8E8E8E]  h-[150px] w-[460px] rounded-[30px]  "
                onChange={(e: any) => {
                  setMintingData((prev: any) => ({
                    ...prev,
                    description: e.target.value,
                  }));
                }}
              />
            </div>

            <div className="flex mt-[40px]">
              <span className="register-form-name">기부 일자</span>
              <div className="flex items-center justify-center">
                <div className="border-none flex items-center justify-center bg-gray40 text-gray80 h-[36px] w-[100px] text-center  rounded-[30px]">
                  {new Date().getFullYear()}
                </div>
                <span className="text-gray80 pl-2">년</span>
              </div>
              <div className="flex items-center justify-center ml-4">
                <div className="border-none flex items-center justify-center bg-gray40 text-gray80 h-[36px] w-[80px] text-center  rounded-[30px]">
                  {new Date().getMonth() + 1}
                </div>
                <span className="text-gray80 pl-2">월</span>
              </div>
              <div className="flex items-center justify-center  ml-5">
                <div className="border-none flex items-center justify-center bg-gray40 text-gray80 h-[36px] w-[80px] text-center  rounded-[30px]">
                  {new Date().getDate()}
                </div>
                <span className="text-gray80 pl-2">일</span>
              </div>
            </div>
            <div className="flex mt-[40px]">
              <span className="register-form-name">쿠폰 번호</span>
              <div>
                <div className="border-gray40  min-h-[38px]  flex items-center justify-center text-[#8E8E8E]  border w-[230px]  rounded-[30px]">
                  {couponNum}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="sm:ml-[140px] relative  w-[400px]">
              <h3 className="font-bold  text-gray100 mb-[16px]">
                파일 업로드 및 미리보기
              </h3>
              {file ? (
                <img
                  src={URL.createObjectURL(file)}
                  className="sm:w-[420px] sm:h-[420px] relative object-cover "
                  alt="선택한 이미지"
                />
              ) : (
                <div className="sm:w-[420px] sm:h-[420px] bg-[#F7F7F7] flex items-center justify-center">
                  이미지를 선택하세요
                </div>
              )}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                className="absolute right-[14px] top-[58px] rounded-[30px] h-[34px] flex items-center justify-center bg-brand100 text-white w-[126px] text-center cursor-pointer"
              >
                <span className="mr-2"> 파일 업로드</span>
                <UploadSVG fillColor="#ffffff" height={16} width={16} />
              </button>

              <div className="flex flex-row items-center w-full mt-[140px]">
                <input
                  type="checkbox"
                  className="rounded-full border border-brand100 checked:bg-brand100"
                  checked={isAgreed}
                  onChange={(e) => {
                    setIsAgreed(e.target.checked);
                  }}
                />
                <h3 className="text-brand100 ml-2 font-semibold">
                  생성된 NFT에 대해 수정이 불가능합니다. 동의하십니까?
                </h3>
              </div>

              <div className="mt-[60px]  w-full flex items-center justify-center">
                <button
                  type="button"
                  className="bg-brand100 text-white w-[140px] h-[46px] rounded-[30px] text-[22px]"
                  onClick={() => {
                    const userStatusString: any =
                      sessionStorage.getItem("login_user");
                    const userStatus = JSON.parse(userStatusString);

                    if (
                      mintingData.coupondataId !== "" &&
                      mintingData.description !== "" &&
                      mintingData.image !== null &&
                      mintingData.name !== ""
                    ) {
                      if (userStatus.walletType == "METAMASK") {
                        MintingEtcNFT(
                          mintingData,
                          petId,
                          setIsActiveModal,
                          nftType
                        );
                      } else if (userStatus.walletType == "KAIKAS") {
                        KlaytnEtcMinting(
                          mintingData,
                          petId,
                          setIsActiveModal,
                          nftType
                        );
                      }
                    } else {
                      WarningToastify("입력 폼을 모두 입력해주세요.");
                      return;
                    }
                  }}
                >
                  민팅하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationRegisterNFT;
