import axios from "axios";

const SellPayment = async (tokenId: number, period: number) => {
  const userStatusString: any = sessionStorage.getItem("login_user");
  const userStatus = JSON.parse(userStatusString);

  const url = process.env.REACT_APP_BLOCKCHAIN_API + "/api/sellNFT";

  const res = await axios.post(url, {
    privateKey: userStatus.privateKey,
    tokenID: tokenId,
    period: period,
  });

  return {
    auctionNumber: res.data.auction_index,
    saleTransactionHash: res.data.transactionHash,
  };
};

export default SellPayment;
