import MyPageNftDetail from "../../../../components/UI/nftDetail/mypage/agency/MyPageNftDetail";

const MyNftDetailPage = () => {
  return (
    <div>
      <MyPageNftDetail />
    </div>
  );
};

export default MyNftDetailPage;
