import LoadingLottie from "../../../../common/animation/animation";

const IsBuyingModalContent = () => {
  return (
    <div className="w-[220px]  items-center justify-center text-center flex flex-col ">
      <span> 구매중 입니다...</span>
      <LoadingLottie />
      <span className="text-brand100">잠시만 기다려주세요</span>
      <span className="text-[12px] mt-[5px]">
        * 구매 시간은 1~5분 소요될 수 있습니다.
      </span>
    </div>
  );
};

export default IsBuyingModalContent;
