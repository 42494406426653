import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import axios from "axios";
import { useNavigate } from "react-router-dom";

type NoticeItem = {
  noticeId: number;
  title: string;
  createdAt: string;
};

const NoticeList = () => {
  const [page, setPage] = useState(0); // 페이지 번호를 0부터 시작하도록 변경
  const itemsPerPage = 12;
  const [totalPages, setTotalPages] = useState(0);
  const [noticeList, setNoticeList] = useState([]);
  const navigate = useNavigate();

  const fetchNoticeListOnPageChange = async (selectedPage: any) => {
    try {
      const url = `${
        process.env.REACT_APP_SERVER_API
      }/admin/notices/list?page=${selectedPage - 1}&size=10`;
      const response = await axios.get(url);
      setNoticeList(response.data.data.content);
    } catch (error) {
      console.error("통신 에러:", error);
    }
  };

  const handlePageChange = (selectedPage: any) => {
    setPage(selectedPage);
    fetchNoticeListOnPageChange(selectedPage);
  };
  const fetchNFTList = async () => {
    try {
      const url = `${process.env.REACT_APP_SERVER_API}/admin/notices/list?page=${page}&size=10`;
      const response: any = await axios.get(url);
      if (response.data && response.data.status === 200) {
        setTotalPages(response.data.data.totalPages);
        setNoticeList(response.data.data.content);
      } else {
        console.error("공지사항 데이터를 불러오는데 실패했습니다.");
      }
    } catch (error) {
      console.error("에러:", error);
    }
  };

  useEffect(() => {
    fetchNFTList();
  }, [page]);
  return (
    <div>
      <div className="bg-white pb-4 px-10 rounded-md w-full">
        <h3 className="sm:text-[40px] text-[24px] mt-[100px] text-brand100 font-semibold">
          공지사항
        </h3>
        <div className="overflow-x-auto mt-6">
          <table className="table-auto border-collapse w-full">
            <thead>
              <tr className="rounded-lg text-gray-600 text-left sm:text-[18px] text-[12px]">
                <th
                  className="px-4 py-3 bg-gray-200 sm:w-[10%]"
                  style={{ backgroundColor: "#f8f8f8" }}
                >
                  순번
                </th>
                <th
                  className="px-4 py-2 sm:w-[70%]"
                  style={{ backgroundColor: "#f8f8f8" }}
                >
                  공지명
                </th>
                <th
                  className="px-6 py-2 hidden sm:table-cell"
                  style={{ backgroundColor: "#f8f8f8" }}
                >
                  게시 날짜
                </th>
              </tr>
            </thead>
            <tbody className="sm:text-[22px] font-normal text-gray-700">
              {noticeList.map((item: NoticeItem, index: number) => {
                return (
                  <tr
                    className="hover:bg-gray-100 border-b border-gray-200 py-10 cursor-pointer"
                    key={index}
                    onClick={() => {
                      navigate(`/notice/detail/${item.noticeId}`);
                    }}
                  >
                    <td className="px-6 py-4">{index + 1}</td>
                    <td className="px-4 py-4">{item.title}</td>
                    <td className="py-4 hidden sm:table-cell">
                      {item.createdAt.slice(0, 10)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex w-full items-center justify-center sm:mt-[60px]">
          {/* Pagination 컴포넌트에 tailwind CSS 클래스 추가 */}
          <Pagination
            activePage={page}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={totalPages * itemsPerPage}
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass="inline-block mx-1 sm:text-[22px] text-[16px] font-semibold px-2 py-1 rounded-lg cursor-pointer mb-[80px] "
            activeClass=" text-brand100 sm:text-[22px] text-[16px] font-semibold "
            disabledClass="text-[#9f9f9f]"
            innerClass="text-[#9f9f9f]"
            prevPageText={<div className="text-brand100">&#60;</div>}
            nextPageText={<div className="text-brand100">&#62;</div>}
            firstPageText={<div className="text-brand100">&#171;</div>}
            lastPageText={<div className="text-brand100">&#187;</div>}
          />
        </div>
      </div>
    </div>
  );
};

export default NoticeList;
