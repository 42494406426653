const MatchChainIdAndRouterAdd = (currentNetwork: string) => {
  if (currentNetwork == "0xaa36a7") {
    return process.env.REACT_APP_ROUTER_SEPOLIA_CONTRACT_ADDRESS;
  } else if (currentNetwork == "0x152") {
    return process.env.REACT_APP_ROUTER_CRONOS_CONTRACT_ADDRESS;
  } else if (currentNetwork == "0x3e9") {
    return process.env.REACT_APP_KLAYTN_ROUTER_CONTRACT;
  } else if (currentNetwork == "0x61") {
    return process.env.REACT_APP_ROUTER_BNB_CONTRACT_ADDRESS;
  }
};
export default MatchChainIdAndRouterAdd;
