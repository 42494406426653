import React, { ReactNode } from "react";
import CloseSVG from "../../../common/svg/CloseSVG";

interface ModalProps {
  content: ReactNode;
  isActiveModal: boolean; // isActiveModal prop을 추가합니다.
  closeModal: React.Dispatch<React.SetStateAction<boolean>>; // 모달을 닫는 함수를 부모로부터 전달받습니다.
}

const Modal: React.FC<ModalProps> = ({
  content,
  isActiveModal,
  closeModal,
}) => {
  return (
    <div className={`modal-container ${isActiveModal ? "flex" : "hidden"}`}>
      <div className="modal-content">
        {/* <div
          className="modal-close-icon"
          onClick={() => {
            closeModal(false);
          }}
        >
          <CloseSVG fillColor="#f5d9c6" width={30} height={30} />
        </div> */}
        {content}
      </div>
    </div>
  );
};

export default Modal;
