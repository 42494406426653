import NoticeList from "./NoticeList";

const NoticeContainer = () => {
  return (
    <div>
      <NoticeList />
    </div>
  );
};

export default NoticeContainer;
