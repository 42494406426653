import KakaoMap from "./KakaoMap";
import SearchMapContent from "./SearchMapContent";

const SearchMapContainer = () => {
  return (
    <div className="sm:flex hidden mt-[180px] sm:flex-row flex-col sm:mb-[340px] w-full ">
      <SearchMapContent />
      <KakaoMap />
    </div>
  );
};

export default SearchMapContainer;
