import EmergencyIntroSlide from "./EmergencyIntroSlide";
import { Trans, useTranslation } from "react-i18next";

const EmergencyIntroContainer = () => {
  const { t } = useTranslation();

  return (
    <div className="flex-col sm:mt-[120px] mt-[80px] items-center w-full md:flex px-0 hidden ">
      <div className="sm:w-[80%]">
        <h3 className="sm:text-[36px] text-[24px] font-bold">
          {/* <Trans>{t("page:main-emergency-title")}</Trans> */}
          여러분을 기다리고 있어요
        </h3>
        <h5 className="sm:text-[22px] text-[12px]  mt-4 ">
          {/* <Trans>{t("page:main-emergency-content")}</Trans> */}
          따뜻한 손길이 필요한 아이들을 도와주세요
        </h5>
      </div>
      <EmergencyIntroSlide />
    </div>
  );
};

export default EmergencyIntroContainer;
