import UserMyPageContent from "../../../components/UI/mypage/user/UserMyPageContent";

const UserMyPage = () => {
  return (
    <div>
      <UserMyPageContent />
    </div>
  );
};

export default UserMyPage;
