import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en";
import ko from "./locales/ko";

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: "ko",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    ko: ko,
    en: en,
  },
  defaultNS: "translations",
  ns: "translations",
});

export default i18n;
