import axios from "axios";
import WarningToastify from "../../UI/common/WarningToastify";

const GetRelatedNFTInfo = async (nftId: number, page: number) => {
  try {
    const url =
      process.env.REACT_APP_SERVER_API +
      "/nfts/gallery/" +
      nftId +
      "?page=" +
      page;
    const res = await axios.get(url);
    return { data: res.data.data };
  } catch (error: any) {
    if (error.response.data.message) {
      WarningToastify(error.response.data.message);
      return;
    } else {
      alert("알 수 없는 에러가 발생했습니다.");
    }
  }
};

export default GetRelatedNFTInfo;
