import ConnectKaikas from "../../Feat/wallet/klaytn/ConnectKaikas";
import { ConnectMetaMask } from "../../Feat/wallet/ConnectMetaMask";
import six_steps_logo from "../../../common/images/6_steps.jpeg";
import KakaoSVG from "../../../common/svg/KakaoSVG";
import metamask_logo from "../../../common/images/metamask2x.png";
import kaikas_logo from "../../../common/images/kaikas_logo.png";
import Login from "../../Feat/auth/Login";
import WarningToastify from "../common/WarningToastify";
import SocialKakaoLogin from "../../Feat/auth/SocialKakaoLogin";

const LoginModalContent = () => {
  return (
    <div className="flex justify-center items-center w-[320px] py-5">
      <div className="justify-center items-center flex flex-col">
        <img src={six_steps_logo} className="w-[100px] h-fit" />

        <h3 className="text-2xl font-semibold mb-[30px] text-[#470B11] mt-[30px]">
          로그인
        </h3>
        <div className="flex flex-col space-y-2">
          <button
            type="button"
            className="px-2 cursor-pointer w-[230px] flex items-center justify-center shadow-lg rounded-[14px] shadow-gray-300 py-3 text-white bg-[#233447]"
            onClick={async () => {
              const connectWallet = await ConnectMetaMask();
              const walletType = "METAMASK";
              if (connectWallet) {
                const result: any = await Login(connectWallet, walletType);
                if (result) {
                  window.location.href = "/";
                }
              } else {
                WarningToastify("로그인 실패");
                return;
              }
            }}
          >
            <img src={metamask_logo} className="w-[24px] h-[24px]" />
            <span className="ml-4">메타마스크 로그인</span>
          </button>
          <button
            type="button"
            className="px-2 cursor-pointer w-[230px] flex items-center justify-center shadow-lg rounded-[14px] shadow-gray-300 py-3 text-white bg-[#3366FF]"
            onClick={async () => {
              const connectWallet = await ConnectKaikas();
              const walletType = "KAIKAS";
              const result: any = await Login(connectWallet, walletType);
              if (result) {
                window.location.href = "/";
              }
            }}
          >
            <img src={kaikas_logo} className="w-[22px] h-[22px]" />
            <span className="ml-4"> 카이카스 로그인</span>
          </button>
          <button
            type="button"
            className="px-2 cursor-pointer w-[230px] flex items-center justify-center shadow-lg rounded-[14px] shadow-gray-300 py-3 text-black bg-[#FEE500]"
            onClick={() => {
              SocialKakaoLogin();
            }}
          >
            <KakaoSVG fillColor="ffffff" width={20} height={20} />
            <span className="ml-3"> 카카오 로그인</span>
          </button>
        </div>
        <div
          className="cursor-pointer underline text-gray80 mt-[40px]"
          onClick={() => {
            window.location.href = "/register";
          }}
        >
          회원가입
        </div>
      </div>
    </div>
  );
};

export default LoginModalContent;
