import axios from "axios";

const BuyPayment = async (auctionNum: any) => {
  const url = process.env.REACT_APP_BLOCKCHAIN_API + "/api/buyNFT";
  const userStatusString: any = sessionStorage.getItem("login_user");
  const userStatus = JSON.parse(userStatusString);

  try {
    const res = await axios.post(url, {
      privateKey: userStatus.privateKey,
      auction_index: auctionNum,
    });
    return {
      result: true,
      buyerWalletAddress: res.data.bidder,
      buyTransactionHash: res.data.transactionHash,
    };
  } catch (error: any) {}
};

export default BuyPayment;
