import RegisterSuccessContent from "../../components/UI/auth/register/RegisterSuccessContent";

const RegisterSuccessPage = () => {
  return (
    <div>
      <RegisterSuccessContent />
    </div>
  );
};

export default RegisterSuccessPage;
