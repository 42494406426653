import axios from "axios";
import WarningToastify from "../../UI/common/WarningToastify";

const GetBuyingNFTList = async (queryParams: any) => {
  const url = `${process.env.REACT_APP_SERVER_API}/nfts/search`;
  const headers = {
    Accept: "application/json;charset=UTF-8",
  };

  try {
    const response = await axios.get(url, {
      headers,
      params: queryParams,
    });

    return response;
  } catch (error: any) {
    if (error.response.data.message) {
      WarningToastify(error.response.data.message);
      return;
    } else {
      alert("알 수 없는 에러가 발생했습니다.");
    }
  }
};

export default GetBuyingNFTList;
