import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useRecoilState } from "recoil";
import { activeMobileMenuListState } from "../../../utils/recoil/mobile/atom";

const MainLayout: React.FC<ParentLayoutProps> = ({ children }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isActiveMobileMenu, setIsActiveMobileMenu] = useRecoilState(
    activeMobileMenuListState
  );

  return (
    <div className="flex sm:items-center sm:justify-center flex-col">
      <Header />
      {/* <div className="min-h-screen max-w-[1360px] w-full">{children}</div> */}

      {!isActiveMobileMenu && (
        <div className="min-h-screen sm:max-w-[1360px] w-full">{children}</div>
      )}

      <Footer />
    </div>
  );
};

export default MainLayout;
