import { useParams } from "react-router-dom";
import LeftArrow from "../../../../common/svg/LeftArrow";
import axios from "axios";
import NotiToastify from "../../common/NotiToastify";
import WarningToastify from "../../common/WarningToastify";
import { useEffect, useState } from "react";

type NoticeDetailData = {
  noticeId: number;
  title: string;
  content: string;
  createdAt: string;
  modifiedAt: string;
};

const NoticeDetailContainer = () => {
  const { id }: any = useParams();
  const [noticeDetailData, setNoticeDetailData] = useState<
    NoticeDetailData | undefined
  >(undefined);

  const getNoticeDetail = async () => {
    const url = process.env.REACT_APP_SERVER_API + "/admin/notices/info/" + id;
    try {
      const response = await axios.get(url);
      console.log(response.data.data.content);
      setNoticeDetailData(response.data.data);
    } catch (error: any) {
      if (error.response.data.message) {
        NotiToastify(error.response.data.message);
      } else {
        WarningToastify("알 수 없는 에러가 발생했습니다. ");
        return;
      }
    }
  };
  useEffect(() => {
    getNoticeDetail();
  }, []);

  return (
    <div className="flex items-center justify-center w-full">
      <div className="flex flex-col w-[65%] mt-[40px]">
        <div className="w-full mb-[20px]  cursor-pointer">
          <LeftArrow width={34} height={34} fillColor="#868686" />
        </div>

        <h3 className="text-brand100 sm:text-[32px] mt-[30px]">
          {noticeDetailData?.title}
        </h3>
        <hr className="w-full border-[1.8px] border-brand100 my-[20px]" />
        <div className="flex flex-col mb-[40%] whitespace-pre-line sm:text-[20px]">
          <h3 className="mt-[10%]">{noticeDetailData?.content}</h3>
        </div>
      </div>
    </div>
  );
};

export default NoticeDetailContainer;
