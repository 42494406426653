import { useEffect, useState } from "react";
import SellNFT from "../../../../Feat/nft/SellNFT";
import NotiToastify from "../../../common/NotiToastify";
import IsSellingModalContent from "./IsSellingModalContent";
import Modal from "../../../common/Modal";
import WarningToastify from "../../../common/WarningToastify";
import KlaytnSellNFT from "../../../../Feat/nft/klaytn/KlaytnSellNFT";
import { ConnectMetaMask } from "../../../../Feat/wallet/ConnectMetaMask";
import ValidateLoginWalletList from "../../../../Feat/wallet/ValidateLoginWalletList";
import ConnectKaikas from "../../../../Feat/wallet/klaytn/ConnectKaikas";
import { unstable_renderSubtreeIntoContainer } from "react-dom";
import SellPayment from "../../../../Feat/payment/SellPayment";
import SendSellNFTInfoToServer from "../../../../Feat/nft/SendSellNFTInfoToServer";

const WritePriceAndDateBuyModalContent = (props: any) => {
  const [price, setPrice] = useState(0);
  const [date, setDate] = useState(0);
  const tokenId = props.props.tokenId;
  const nftId = props.props.nftId;
  const [isActiveSelling, setIsActiveSelling] = useState(false);

  useEffect(() => {
    const userStatusString: any = sessionStorage.getItem("login_user");
    const userStatus = JSON.parse(userStatusString);
    if (props.props.detailData.network == "KAIKAS") {
      if (userStatus.walletType == "KAIKAS") {
        return;
      } else if (userStatus.walletType == "METAMASK") {
        WarningToastify("현재 NFT는 카이카스 지갑으로 민팅되었습니다.");
      }
    }
  }, []);

  return (
    <div className="flex flex-col">
      <Modal
        content={<IsSellingModalContent />}
        isActiveModal={isActiveSelling}
        closeModal={setIsActiveSelling}
      />
      <h3>price</h3>
      <input
        type="text"
        maxLength={8}
        onChange={(e: any) => {
          setPrice(e.target.value);
        }}
      />
      <h3>date</h3>
      <input
        min={1}
        type="number"
        onChange={(e: any) => {
          setDate(e.target.value);
        }}
      />
      <button
        type="button"
        onClick={async () => {
          const userStatusString: any = sessionStorage.getItem("login_user");
          const userStatus = JSON.parse(userStatusString);
          const priceNumber = Number(price);

          if (isNaN(priceNumber) || priceNumber <= 0) {
            alert("가격은 숫자 형식으로만 입력할 수 있으며 0보다 커야 합니다.");
            setIsActiveSelling(false);
            return;
          }

          if (date <= 0) {
            WarningToastify("판매 일자는 1일 이상이어야 합니다.");
            setIsActiveSelling(false);
            return;
          }
          if (props.props.detailData.network == "KLAYTN") {
            if (userStatus.walletType == "KAIKAS") {
              const currentWallet = await ConnectKaikas();
              const walletValidateStatus = await ValidateLoginWalletList(
                currentWallet
              );
              if (!walletValidateStatus) {
                alert("민팅시 등록된 지갑으로 연결해주세요.");
                window.location.reload();
                return;
              }
              setIsActiveSelling(true);

              const res: any = await KlaytnSellNFT(price, tokenId, date, nftId);
              if (res.success) {
                setIsActiveSelling(false);
                window.location.href = "/agency/mypage";
                NotiToastify("판매가 정상적으로 완료되었습니다.");
              }
            } else if (userStatus.walletType == "METAMASK") {
              WarningToastify("해당 NFT는 카이카스로 판매가능합니다.");
              return;
            }
          } else if (
            props.props.detailData.network == "SEPOLIA" ||
            props.props.detailData.network == "BNB" ||
            props.props.detailData.network == "CRONOS"
          ) {
            if (userStatus.walletType == "METAMASK") {
              const currentWallet = await ConnectMetaMask();
              const walletValidateStatus = await ValidateLoginWalletList(
                currentWallet
              );
              if (!walletValidateStatus) {
                alert("민팅시 등록된 지갑으로 연결해주세요.");
                window.location.reload();

                return;
              }
              setIsActiveSelling(true);

              const res: any = await SellNFT(tokenId, price, date, nftId);
              setIsActiveSelling(false);
              if (res.status) {
                NotiToastify("판매에 성공했습니다.");
                window.location.href = "/agency/mypage";
              } else if (res.status == false) {
                window.location.href = "/agency/mypage/nftDetail/" + nftId;
                // alert(`${res.message}`);
              }
            } else if (userStatus.walletType == "KAIKAS") {
              WarningToastify("해당 NFT는 메타마스크로 판매가능합니다.");
              return;
            }
          } else if (props.props.detailData.network == "WHATCON") {
            setIsActiveSelling(true);
            if (userStatus.walletType !== "WHATCON") {
              WarningToastify("해당 NFT는 왓콘 지갑으로만 판매할 수 있습니다.");
            }
            if (isNaN(priceNumber) || priceNumber <= 0) {
              alert(
                "가격은 숫자 형식으로만 입력할 수 있으며 0보다 커야 합니다."
              );
              setIsActiveSelling(false);
              return;
            }

            if (date <= 0) {
              WarningToastify("판매 일자는 1일 이상이어야 합니다.");
              setIsActiveSelling(false);
              return;
            }

            const res = await SellPayment(tokenId, date);
            const data = {
              nftId,
              sellerWalletAddress: userStatus.loginAddress,
              dueDate: date,
              price,
              auctionNumber: res.auctionNumber,
              network: "WHATCON",
              saleTransactionHash: res.saleTransactionHash,
            };

            const result: any = await SendSellNFTInfoToServer(data);
            if (result.result) {
              setIsActiveSelling(false);
              window.location.href = "/agency/mypage";
              NotiToastify("판매가 정상적으로 완료되었습니다.");
            }
          }
        }}
        className=" p-3 mt-[20px] bg-brand100 text-white"
      >
        확인
      </button>
      <button
        type="button"
        className="bg-brand100 mt-[10px] text-white h-[48px] cursor-pointer"
        onClick={() => {
          window.location.href = `/agency/mypage/nftDetail/${nftId}`;
        }}
      >
        취소
      </button>
    </div>
  );
};

export default WritePriceAndDateBuyModalContent;
