import KlaytnSetProvider from "./KlaytnSetProvider";
import SendWithdrawInfoToServer from "../SendWithdrawInfoToServer";
const router_abi = require("../../../../utils/contract/MarketplaceRouter.json");

const KlaytnWithdraw = async (auctionNum: any, saleId: any) => {
  const router_contract = process.env.REACT_APP_KLAYTN_ROUTER_CONTRACT;

  try {
    const provider = await window.klaytn;
    const setP: any = await KlaytnSetProvider(provider);
    if (!setP.success) {
      return new Error(setP.Error);
    }
    const p = setP.provider;
    const account = (await p.getAccounts())[0];
    const contract = new p.Contract(router_abi.abi, router_contract);
    contract.options.gas = 5000000;
    const result = await contract.methods
      .withdraw(
        process.env.REACT_APP_BUY_AUCTION_BAOBAB_CONTRACT_ADDRESS, // platform
        auctionNum
      )
      .send({ from: account });
    if (result.status && result.transactionhash != "") {
      const res = await SendWithdrawInfoToServer(saleId);
      return {
        success: true,
        data: { index: result.events._Withdraw.returnValues.index },
        transactionHash: result.transactionHash,
      };
    } else {
      return new Error(result);
    }
  } catch (err: any) {
    return new Error(err);
  }
};

export default KlaytnWithdraw;
