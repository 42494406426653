import EventDetail from "../../components/UI/event/eventDetail/EventDetail";

const EventDetailPage = () => {
  return (
    <div>
      <EventDetail />
    </div>
  );
};

export default EventDetailPage;
