const SocialKakaoLogin = () => {
  {
    const REST_API_KEY = process.env.REACT_APP_KAKAO_MAP_REST_API_KEY;
    const redirectURL =
      window.location.protocol + "//" + window.location.host + "/login/social";
    // oauth 요청 URL
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${redirectURL}&response_type=code`;
    window.location.href = kakaoURL;
  }
};

export default SocialKakaoLogin;
