import axios from "axios";

const WithdrawPayment = async (auctionNum: any) => {
  const userStatusString: any = sessionStorage.getItem("login_user");
  const userStatus = JSON.parse(userStatusString);
  try {
    const url = process.env.REACT_APP_BLOCKCHAIN_API + "/api/withdraw";
    const res = await axios.post(url, {
      privateKey: userStatus.privateKey,
      auction_index: auctionNum,
    });

    return {
      result: res.data.success,
      aunctionNum: res.data.auction_num,
      transactionHash: res.data.transactionHash,
    };
  } catch (error: any) {
    return { result: false };
  }
};

export default WithdrawPayment;
