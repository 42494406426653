const { kakao } = window;

const geoCoder = new kakao.maps.services.Geocoder();

const SearchCoords = (address: string) => {
  return new Promise((resolve, reject) => {
    geoCoder.addressSearch(address, (result: any, status: any) => {
      if (status === kakao.maps.services.Status.OK) {
        const coords = new kakao.maps.LatLng(result[0].x, result[0].y);
        resolve(coords);
      } else {
        reject(status);
      }
    });
  });
};

export default SearchCoords;
