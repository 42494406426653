import InstitutionRegister from "../../../components/UI/auth/register/institution/InstitutionRegister";

const InstitutionRegisterPage = () => {
  return (
    <div>
      <InstitutionRegister />
    </div>
  );
};

export default InstitutionRegisterPage;
