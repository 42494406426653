import React from "react";
import DonationRegisterNFT from "../../../components/UI/nftUpload/donation/DonationRegisterNFT";

const DonationNFTPage = () => {
  return (
    <div>
      <DonationRegisterNFT />
    </div>
  );
};

export default DonationNFTPage;
