import RegistRegisterNFT from "../../../components/UI/nftUpload/register/RegistRegisterNFT";

const RegisterNFTPage = () => {
  return (
    <div>
      <RegistRegisterNFT />
    </div>
  );
};

export default RegisterNFTPage;
