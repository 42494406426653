import SendBuyNFTInfoToServer from "../SendBuyNFTInfoToServer";
import KlaytnSetProvider from "./KlaytnSetProvider";
const router_abi = require("../../../../utils/contract/MarketplaceRouter.json");

const KlaytnBuyingNFT = async (
  auctionNum: number,
  price: number,
  nftId: number,
  saleId: number
) => {
  try {
    const provider = await window.klaytn;
    const setP: any = await KlaytnSetProvider(provider);
    const router_contract = process.env.REACT_APP_KLAYTN_ROUTER_CONTRACT;
    if (!setP.success) {
      return new Error(setP.Error);
    }
    const p = setP.provider;
    const account = (await p.getAccounts())[0];

    const contract = new p.Contract(router_abi.abi, router_contract);
    contract.options.gas = 5000000;

    const result = await contract.methods
      .bid(
        process.env.REACT_APP_BUY_AUCTION_BAOBAB_CONTRACT_ADDRESS, // platform
        auctionNum, // platform index - 이 플랫폼에서 생성된 5번째 경매
        String(price * 10 ** 18) // price
      )
      .send({ from: account, value: String(price * 10 ** 18) });

    if (result.status && result.transactionHash != "") {
      const data = {
        saleId: saleId,
        nftId: nftId,
        buyerWalletAddress: result.events._Bid.returnValues.bider,
        buyTransactionHash: result.events._Bid.transactionHash,
        network: "KLAYTN",
      };
      const serverResult = await SendBuyNFTInfoToServer(data);

      return {
        success: true,
        data: {
          index: result.events._Bid.returnValues._index,
          bider: result.events._Bid.returnValues.bider,
          price: result.events._Bid.returnValues.price,
        },
        transactionHash: result.transactionHash,
      };
    } else {
      return new Error(result);
    }
  } catch (err: any) {
    return false;
  }
};

export default KlaytnBuyingNFT;
