import WarningToastify from "../../UI/common/WarningToastify";
import instance from "../axios/instance";

const GetLoginUserInfo = async () => {
  try {
    const url = process.env.REACT_APP_SERVER_API + "/users/profile";
    const response = await instance.get(url);
    if (response.data.status == 200) {
      return { data: response.data.data, message: "success", status: true };
    } else {
      return {
        data: response.data.data,
        message: response.data.message,
        status: false,
      };
    }
  } catch (error: any) {
    if (error.response.data.message) {
      WarningToastify(error.response.data.message);
      return;
    } else {
      alert("알 수 없는 에러가 발생했습니다.");
    }
    return { data: "", message: "fail", status: false };
  }
};

export default GetLoginUserInfo;
