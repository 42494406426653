import RegisterMain from "../../components/UI/auth/register/RegisterMain";

const RegisterMainPage = () => {
  return (
    <div>
      <RegisterMain />
    </div>
  );
};

export default RegisterMainPage;
