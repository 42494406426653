const CheckCurrentKlaytnNetwork = async () => {
  try {
    if (typeof window.klaytn === "undefined") {
      return new Error("Please install Kaikas!");
    }
    const accounts = await window.klaytn.enable();
    const provider = await window.klaytn;
    const chainId = await provider.networkVersion;
    const account = accounts[0];
    return { chainId: chainId, account: account };
  } catch (err: any) {
    return new Error(err);
  }
};

export default CheckCurrentKlaytnNetwork;
