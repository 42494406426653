import WarningToastify from "../../UI/common/WarningToastify";

export const ConnectMetaMask = async () => {
  try {
    if (typeof window.ethereum == "undefined") {
      alert("메타마스크 지갑을 먼저 설치해주세요");
      return;
    }

    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    if (accounts[0]) {
      sessionStorage.setItem("user_connect_wallet", "METAMASK");
      return accounts[0];
    } else {
      WarningToastify("지갑주소 불러오기가 실패했습니다. ");
      throw new Error("지갑주소 불러오기가 실패했습니다. ");
    }
  } catch (err) {
    console.error(err);
    alert("메타마스크 연결 에러");
    return;
  }
};
