import NftDetailContainer from "../../../../components/UI/nftDetail/nftList/NftDetailContainer";

const NftDetailPage = () => {
  return (
    <div>
      <NftDetailContainer />
    </div>
  );
};

export default NftDetailPage;
