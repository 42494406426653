const ValidateLoginWalletList = async (walletAddress: string) => {
  const storedWalletList = sessionStorage.getItem("loginWalletList");
  if (storedWalletList) {
    const loginWalletList = JSON.parse(storedWalletList);
    const matchingWallet = loginWalletList.find(
      (wallet: { walletAddress: string }) =>
        wallet.walletAddress === walletAddress
    );
    return matchingWallet !== undefined;
  }
  // 'loginWalletList'가 없는 경우는 항상 false 반환
  return false;
};

export default ValidateLoginWalletList;
