import React from "react";
import RegisterAgree from "../../components/UI/auth/register/RegisterAgree";

const RegisterAgreePage = () => {
  return (
    <div>
      <RegisterAgree />
    </div>
  );
};

export default RegisterAgreePage;
