import React from "react";
import whatcon from "../../../common/images/whatcon_logo_image.png";
import InstagramSVG from "../../../common/svg/InstagramSVG";
import FacebookSVG from "../../../common/svg/FacebookSVG";
import DiscordSVG from "../../../common/svg/DiscordSVG";
import { useNavigate } from "react-router-dom";
import tinkerbell_logo from "../../../common/images/tinkerbell_logo.png";
import six_steps_text_logo from "../../../common/images/six_steps_text_logo.png";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <footer className="text-gray-600 body-font  flex border-gray-100 border shadow-md w-full flex-col px-[120px] ">
      <div className=" flex flex-wrap p-5 flex-col md:flex-row items-center">
        {/* <div className="space-x-10 sm:flex hidden">
          <DiscordSVG width={28} height={28} fillColor="#606060" />
          <InstagramSVG width={28} height={28} fillColor="#606060" />
          <FacebookSVG width={28} height={28} fillColor="none" />
        </div> */}

        <nav className=" md:mr-auto flex flex-wrap items-center text-base justify-center">
          <div className=" w-full flex">
            <span className="text-[24px] font-bold text-black flex">
              <img src={whatcon} className="w-[230px]" />
            </span>
          </div>
        </nav>

        <div className="space-x-[35px] sm:flex hidden">
          <button
            type="button"
            onClick={() => {
              const url = "https://ca.whatconn.co.kr/file/terms_of_service.pdf";
              window.open(url, "_blank", "noopener, noreferrer");
            }}
          >
            이용약관
          </button>
          <button
            type="button"
            onClick={() => {
              navigate("/event");
            }}
          >
            이벤트
          </button>
          <button
            type="button"
            onClick={() => {
              window.location.href = "/notice";
            }}
          >
            공지사항
          </button>
        </div>
      </div>
      <div className="flex flex-col px-[20px]">
        {/* <div className="flex text-[16px] text-[#606060]">
          <img src={six_steps_text_logo} className="w-[104px] h-[18px]" />
          <div className="px-[8px]">는 동물보호센터</div>
          <img src={tinkerbell_logo} className="w-[122px] h-[25px]" />
          <div className="px-[8px]">와 함께 합니다.</div>
        </div> */}
        <div className="sm:flex mb-[34px] text-[16px] text-[#606060] hidden">
          <div className="pr-[8px]">전국 300여개의 민간동물보호센터 중</div>
          <img src={tinkerbell_logo} className="w-[122px] h-[25px]" />
          <div className="px-[8px]">외 30여 기관과 함께 합니다.</div>
        </div>
        {/* <div className="mb-[20px]">
          상호명: 왓콘
          <br /> 대표자명: 한영수 <br />
          사업자등록번호: 423-86-02317 <br />
          사업장주소: 제주특별자치도 서귀포시 중정로 86, 3F <br />
          전화번호: 064-805-9700 <br />
          고객센터 전화: 064-805-9700 <br />
        </div> */}
      </div>
    </footer>
  );
};

export default Footer;
