import { useEffect, useState } from "react";
import { Carousel } from "flowbite-react";
import GetMainEvent from "../../../Feat/event/GetMainEvent";

const MainIntroduceSlide = () => {
  const [mainEvent, setMainEvent]: any = useState([]);
  const customTheme = {
    root: {
      leftControl:
        "absolute sm:top-[210px] sm:left-[-65px] top-[100px] left-[-45px] sm:flex hidden  px-4 focus:outline-none  main-left-controller ",
      rightControl:
        "absolute sm:top-[210px] sm:right-[-65px] top-[100px] right-[-45px] sm:flex hidden  px-4 focus:outline-none main-right-controller",
    },
    indicators: {
      active: {
        off: "bg-[#FEEDC0] hover:bg-[#FCC22F] ",
        on: "bg-[#FCC22F] dark:bg-gray-800",
      },
      base: "h-2 w-2 rounded-full",
      wrapper:
        "absolute bottom-[-20px] left-1/2 flex -translate-x-1/2 space-x-3",
    },
  };

  const requestMainEventImageList = async () => {
    const res: any = await GetMainEvent();
    setMainEvent(res.data);
  };

  useEffect(() => {
    requestMainEventImageList();
  }, []);

  return (
    <div>
      <Carousel
        className="sm:w-[496px] sm:h-[496px] w-[320px] h-[320px]  "
        theme={customTheme}
      >
        {mainEvent.map((item: any, index: number) => {
          return (
            <img className="flex h-full p-2" src={item.mainImage} key={index} />
          );
        })}
      </Carousel>
    </div>
  );
};

export default MainIntroduceSlide;
