import { useEffect, useRef, useState } from "react";
import six_steps_logo from "../../../../../common/images/6_steps.jpeg";
import { ConnectMetaMask } from "../../../../Feat/wallet/ConnectMetaMask";
import UploadSVG from "../../../../../common/svg/UploadSVG";
import ConnectKaikas from "../../../../Feat/wallet/klaytn/ConnectKaikas";
import metamask_logo from "../../../../../common/images/metamask2x.png";
import kaikas_logo from "../../../../../common/images/kaikas_logo.png";
import axios from "axios";
import NotiToastify from "../../../common/NotiToastify";
import WarningToastify from "../../../common/WarningToastify";
import Modal from "../../../common/Modal";
import PfpModalContent from "../common/PfpModalContent";

const EmailRegister = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isValidatingEmail, setIsValidatingEmail] = useState(false);
  const [validatedEmail, setValidatedEmail] = useState(false);
  const [emailConfirmStatus, setEmailConfirmStatus] = useState(false);
  const [emailConfirmNum, setEmailConfirmNum] = useState(0);
  const role = "ROLE_USER";
  const [walletList, setWalletList] = useState<{ walletAddress?: string }[]>([
    {},
  ]);
  const [successSendEmail, setSuccessSendEmail] = useState(false);
  const [resendEmailStatus, setResendEmailStatus] = useState(false);
  const [isActivePfpModal, setIsActivePfpModal] = useState(false);
  let selectedFile: any;
  const [imgUrl, setImgUrl] = useState("");

  const requestImgUrl = async () => {
    try {
      const formData = new FormData();
      if (selectedFile !== null) {
        formData.append("image", selectedFile); // 'image'는 서버에서 파일을 받는 필드 이름과 동일해야 합니다.

        const response = await axios.post(
          process.env.REACT_APP_SERVER_API + "/files/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.data.url) {
          setImgUrl(response.data.data.url);
          NotiToastify("이미지 업로드가 되었습니다.");
          return true;
        }
      }
    } catch (error) {
      NotiToastify("파일 업로드에 실패했습니다.");
      return false;
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    selectedFile = e.target.files?.[0] || null;
    setFile(selectedFile);

    if (selectedFile !== null) {
      const requestImgUrlResult = await requestImgUrl();
    }
  };

  const connectMetaMask = async () => {
    try {
      const metamaskWalletAddress = await ConnectMetaMask();
      if (metamaskWalletAddress) {
        const newWalletData = {
          walletAddress: metamaskWalletAddress,
          walletType: "METAMASK",
        };

        if (
          walletList.length === 1 &&
          Object.keys(walletList[0]).length === 0
        ) {
          setWalletList([newWalletData]);
        } else {
          if (
            walletList.some(
              (wallet) => wallet.walletAddress === metamaskWalletAddress
            )
          ) {
            alert("이미 추가된 지갑주소입니다.");
          } else {
            setWalletList([...walletList, newWalletData]);
          }
        }
      } else {
        return;
      }
    } catch (error) {
      console.error("connectMetaMask 오류:", error);
    }
  };

  const connectKaikas = async () => {
    try {
      const kaikasWalletAddress = await ConnectKaikas();
      if (kaikasWalletAddress) {
        const newWalletData = {
          walletAddress: kaikasWalletAddress,
          walletType: "KAIKAS",
        };
        if (
          walletList.length === 1 &&
          Object.keys(walletList[0]).length === 0
        ) {
          setWalletList([newWalletData]);
        } else {
          if (
            walletList.some(
              (wallet) => wallet.walletAddress == kaikasWalletAddress
            )
          ) {
            alert("이미 추가된 지갑주소입니다.");
            return;
          } else {
            setWalletList([newWalletData]);
          }
          setWalletList([...walletList, newWalletData]);
        }
      } else {
        return;
      }
    } catch (error) {
      console.error("connectKaikas 오류:", error);
    }
  };

  const signUp = async () => {
    const emailValid = isEmailValid(email);
    if (emailValid && emailConfirmStatus) {
      if (
        name !== "" &&
        email !== "" &&
        walletList.length !== 0 &&
        imgUrl !== ""
      ) {
        try {
          const data = {
            name,
            email,
            image: imgUrl,
            role: "ROLE_USER",
            adAgreed: true,
            walletList,
          };
          const response = await axios.post(
            process.env.REACT_APP_SERVER_API + "/auth/users/sign-up",
            JSON.stringify(data),
            {
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
            }
          );
          NotiToastify("회원가입이 완료되었습니다. ");
          window.location.href = "/register/success";
        } catch (error: any) {
          if (error.response.data.message) {
            NotiToastify(error.response.data.message);
          } else {
            WarningToastify("회원가입에 실패했습니다.");
            return;
          }
        }
      } else {
        NotiToastify("회원가입 폼을 모두 입력해주세요");
      }
    } else if (!emailConfirmStatus) {
      NotiToastify("이메일 인증을 완료해주세요.");
    }
  };

  const isEmailValid = (email: string) => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return emailRegex.test(email);
  };

  const checkEmailDuplicate = async () => {
    const emailValid = isEmailValid(email);
    if (emailValid) {
      try {
        const url = process.env.REACT_APP_SERVER_API + "/auth/users/email/send";
        const res = await axios.post(
          url,
          { email },
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
            },
          }
        );
        if (res.status == 201) {
          setValidatedEmail(false);
          setIsValidatingEmail(true);
          setSuccessSendEmail(true);
          alert("인증번호가 발송되었습니다.");
        } else {
          WarningToastify("알 수 없는 오류가 발생했습니다. 다시 시도해주세요.");
        }
      } catch (error: any) {
        if (error.response.status == 409) {
          WarningToastify(error.response.data.message);

          setValidatedEmail(false);
          return;
        } else if (error.response.status == 400) {
          WarningToastify(error.response.data.message);
          return;
        }
        if (error.response.data.message !== undefined) {
          alert(error.response.data.message);
          return;
        } else {
          alert("알 수 없는 에러가 발생했습니다.");
          return;
        }
      }
    } else {
      NotiToastify("이메일 형식에 맞게 입력해주세요");
      return;
    }
  };

  const checkEmailNum = async () => {
    const url = process.env.REACT_APP_SERVER_API + "/auth/users/email/verify";
    try {
      const res = await axios.post(
        url,
        { email, code: emailConfirmNum },
        {
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
        }
      );
      if (res.status == 200) {
        setEmailConfirmStatus(true);
      }
    } catch (error: any) {
      if (error.response.status == 409) {
        WarningToastify("인증 번호를 다시 확인해주세요.");
        setResendEmailStatus(true);
        return;
      } else if (error.response.data.message) {
        WarningToastify(error.response.data.message);
        return;
      }
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("login_user")) {
      alert("이미 로그인된 상태입니다.");
      window.location.href = "/";
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center">
      <img src={six_steps_logo} className="w-[120px] h-[100px] mt-[60px]" />
      <h3 className="mt-[10px]">회원 정보 입력</h3>
      <Modal
        content={
          <PfpModalContent
            setImgUrl={setImgUrl}
            setIsActivePfpModal={setIsActivePfpModal}
          />
        }
        isActiveModal={isActivePfpModal}
        closeModal={setIsActivePfpModal}
      />
      <div className="flex flex-col  mt-[120px]">
        <div className="flex mb-[50px]">
          <span className="register-form-name">회원 유형</span>
          <div className="bg-gray40 rounded-[20px] w-[120px] h-[32px] flex items-center justify-center text-center text-gray80">
            일반 회원
          </div>
        </div>
        <div className="flex">
          <span className="register-form-name">이메일</span>
          <div className="flex flex-col">
            <div className="flex">
              <input
                type="text"
                className="rounded-[20px] w-[282px] pl-9 text-gray80 border h-[34px] border-gray-200"
                placeholder="abc@mail.com"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                disabled={successSendEmail}
              />

              {successSendEmail == false && (
                <button
                  type="button"
                  className="register-basic-button ml-5 bg-brand100"
                  onClick={() => {
                    checkEmailDuplicate();
                  }}
                >
                  인증번호 발송
                </button>
              )}
            </div>
            {validatedEmail && (
              <span className="register-basic-note">
                * 이미 존재하는 이메일 입니다.
              </span>
            )}

            {isValidatingEmail && (
              <div>
                <div className="flex mt-[40px] ">
                  <input
                    type="text"
                    className="rounded-[20px] w-[282px] pl-9  text-gray80 border h-[34px] border-gray-200"
                    placeholder="인증번호 입력"
                    maxLength={4}
                    onChange={(e: any) => {
                      setEmailConfirmNum(e.target.value);
                    }}
                    disabled={emailConfirmStatus}
                  />
                  {successSendEmail && !emailConfirmStatus && (
                    <button
                      type="button"
                      className="register-basic-button ml-12 bg-brand100"
                      onClick={() => {
                        checkEmailNum();
                      }}
                    >
                      인증하기
                    </button>
                  )}
                </div>
                {emailConfirmStatus && (
                  <span className="register-basic-note mb-[80px]">
                    * 인증되었습니다.
                  </span>
                )}
                {resendEmailStatus && !emailConfirmStatus && (
                  <div className="flex mb-[80px] items-center">
                    <div className="register-basic-note  ">
                      인증번호가 전송되지 않았나요?
                    </div>
                    <button
                      className="register-basic-note ml-3 underline text-gray-300 font-semibold"
                      onClick={() => {
                        setEmailConfirmNum(0);
                        checkEmailDuplicate();
                        setResendEmailStatus(false);
                      }}
                    >
                      재전송하기
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="flex mt-[40px]">
          <span className="register-form-name">지갑주소</span>
          <div className="flex flex-col space-y-5">
            {walletList.length > 0 && (
              <>
                {walletList.map((wallet, index) => (
                  <div key={index} className="flex">
                    {wallet.walletAddress && (
                      <div className="flex">
                        <input
                          type="text"
                          className="rounded-[20px] bg-[#DFDFDF] text-center text-[#909090] border h-[36px] border-gray-200 w-[420px]"
                          placeholder="지갑주소 입력"
                          value={wallet.walletAddress}
                          disabled
                        />
                        <div
                          className="ml-3 rounded-full cursor-pointer border-[#f5d9c6] border-[2px] text-brand100 w-[30px] h-[30px]  flex items-center justify-center"
                          onClick={() => {
                            const updatedWalletList = [...walletList];
                            updatedWalletList.splice(index, 1);
                            setWalletList(updatedWalletList);
                          }}
                        >
                          <span className="text-[36px] pb-1">-</span>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}

            <div className="flex flex-col w-full ">
              <div className="flex space-x-3  ">
                <button
                  type="button"
                  className="bg-metamask_color rounded-[10px] text-white h-[40px] px-8 flex items-center justify-center"
                  onClick={() => {
                    connectMetaMask();
                  }}
                >
                  <img src={metamask_logo} className="w-[20px] h-[20px]" />

                  <span className="ml-2">메타마스크 지갑 연동</span>
                </button>
                <button
                  type="button"
                  className="bg-kaikas_color rounded-[10px] text-white h-[40px] px-8 flex items-center justify-center"
                  onClick={() => {
                    connectKaikas();
                  }}
                >
                  <img src={kaikas_logo} className="w-[20px] h-[20px]" />
                  <span className="ml-2"> 카이카스 지갑 연동</span>
                </button>
              </div>
              <span className="text-brand100 text-[12px] mb-[80px] mt-[10px]">
                * 지갑 연동 버튼을 눌러서 지갑주소를 추가할 수 있습니다.
                <br />
                연결이 제대로 되지 않는다면 지갑의 도메인 연결상태를
                확인해주세요.
              </span>
            </div>
          </div>
        </div>
        <div className="flex">
          <span className="register-form-name">닉네임</span>
          <div className="flex flex-col">
            <div className="flex">
              <input
                maxLength={10}
                type="text"
                className="rounded-[20px] w-[230px] pl-9 text-gray-400 border h-[34px] border-gray-200"
                placeholder="ex) 식스스탭댄스"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex mt-[40px]">
          <span className="register-form-name">프로필</span>
          <div className="flex flex-col">
            <div className="flex">
              {imgUrl !== "" ? (
                <img
                  src={imgUrl}
                  className="sm:w-[400px] sm:h-[400px] relative"
                  alt="선택한 이미지"
                />
              ) : (
                <div className="sm:w-[400px] sm:h-[400px] bg-[#F7F7F7] flex items-center justify-center">
                  이미지를 선택하세요
                </div>
              )}
              <button
                type="button"
                className="bg-brand100 ml-6 text-white rounded-[20px] px-4 h-[33px] flex items-center justify-center'
              "
                onClick={() => fileInputRef.current?.click()}
              >
                <span className="mr-1"> 파일 업로드</span>
                <UploadSVG width={12} height={12} fillColor="white" />
              </button>
              <button
                type="button"
                className="bg-brand100 ml-6 text-white rounded-[20px] px-4 h-[33px] flex items-center justify-center'
              "
                onClick={() => {
                  setIsActivePfpModal(true);
                }}
              >
                <span className="mr-1"> 프로필 사진 고르기</span>
                <UploadSVG width={12} height={12} fillColor="white" />
              </button>
              <input
                type="file"
                className="hidden"
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>

        <div className="w-full flex items-center justify-center">
          <button
            type="button"
            className="bg-brand100 w-[130px] mt-[80px] mb-[130px] text-[22px] text-white rounded-[30px]   px-3  h-[40px]"
            onClick={() => {
              signUp();
            }}
          >
            가입하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default EmailRegister;
