import IntroduceApp from "./IntroduceApp";

const Introduce = () => {
  return (
    <div className="min-h-screen ">
      <div className="sm:mt-[120px]">
        <IntroduceApp />
      </div>
    </div>
  );
};

export default Introduce;
