import { useEffect, useState } from "react";
import GetPfpList from "../../../../Feat/auth/GetPfpList";
import RefreshSVG from "../../../../../common/svg/RefreshSVG";

const PfpModalContent = (props: any) => {
  const { setImgUrl } = props;
  const { setIsActivePfpModal } = props;
  const [pfpList, setPfpList]: any = useState([]);
  const getPfpListToServer = async () => {
    const res = await GetPfpList();
    setPfpList(res);
  };

  useEffect(() => {
    getPfpListToServer();
  }, []);
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="my-1 text-brand100 text-lg font-semibold">
        프로필 이미지 고르기
      </div>
      <div className="mb-5 text-[14px] text-gray80">
        마음에 드는 사진을 클릭해주세요!
      </div>
      <div className="flex space-x-2">
        {pfpList?.map((imgUrl: string, index: number) => {
          return (
            <img
              key={index}
              src={imgUrl}
              className="w-[100px] h-[100px] hover:border-brand100 hover:border-[2px] cursor-pointer"
              onClick={() => {
                setImgUrl(imgUrl);
                setIsActivePfpModal(false);
              }}
            />
          );
        })}
      </div>
      <div
        className="flex items-center justify-center mt-3 cursor-pointer hover:text-[#646363]"
        onClick={() => {
          getPfpListToServer();
        }}
      >
        <RefreshSVG fillColor="#878787" width={17} height={17} />
        <span className="text-[#878787] hover:text-gray-700 text-[14px] ml-1">
          새로고침
        </span>
      </div>

      <button
        type="button"
        onClick={() => {
          setIsActivePfpModal(false);
        }}
        className="bg-brand100 text-white px-2 w-[100px] mt-[50px] rounded-[30px] h-[36px] cursor-pointer"
      >
        취소
      </button>
    </div>
  );
};

export default PfpModalContent;
