import MatchChainIdAndContractAdd from "../network/MatchChainIdAndContractAdd";

const { ethers } = require("ethers");

const MintNFT = async (currentNetwork: string, ipfsURI: string) => {
  const contractAddress: any = MatchChainIdAndContractAdd(currentNetwork);
  const contractABI = require("../../../utils/contract/StandardNFT.json");
  const provider = new ethers.BrowserProvider(window.ethereum);
  const signer = await provider.getSigner();
  try {
    const contract = new ethers.Contract(
      contractAddress,
      contractABI.abi,
      signer
    );

    const response = await contract.safeMint(signer.address, ipfsURI);
    const result = await response.wait();
    const tokenID = Number(result.logs[0].args[2]);
    const transactionHash = result.hash;
    return { data: { tokenID, transactionHash }, status: true };
  } catch (error: any) {
    if (error.code !== "ACTION_REJECTED") {
      console.error("An error occurred during the minting process:", error);
    } else {
      alert("거부되었습니다");
    }
    alert("오류가 발생했습니다.");
    return { status: false };
  }
};

export default MintNFT;
