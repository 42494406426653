import { useEffect, useRef, useState } from "react";
import six_steps_logo from "../../../../../common/images/6_steps.jpeg";
import UploadSVG from "../../../../../common/svg/UploadSVG";
import WarningToastify from "../../../common/WarningToastify";
import RemoveLoginStatusInSession from "../../../../Feat/auth/RemoveLoginStatusInSession";
import axios from "axios";
import NotiToastify from "../../../common/NotiToastify";
import instance from "../../../../Feat/axios/instance";
import GetLoginUserInfo from "../../../../Feat/user/GetLoginUserInfo";
import ConnectKaikas from "../../../../Feat/wallet/klaytn/ConnectKaikas";
import { ConnectMetaMask } from "../../../../Feat/wallet/ConnectMetaMask";
import metamask_logo from "../../../../../common/images/metamask2x.png";
import kaikas_logo from "../../../../../common/images/kaikas_logo.png";
import LeftArrow from "../../../../../common/svg/LeftArrow";
import Modal from "../../../common/Modal";
import PfpModalContent from "../../../auth/register/common/PfpModalContent";

const UserEditContent = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [userInfo, setUserInfo]: any = useState({});
  const [walletList, setWalletList] = useState<any[]>([{}]);
  const [originWalletList, setOriginWalletList] = useState<string[]>([]);
  const [newWalletList, setNewWalletList] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [isActivePfpModal, setIsActivePfpModal] = useState(false);

  const connectMetaMask = async () => {
    try {
      const metamaskWalletAddress = await ConnectMetaMask();
      if (metamaskWalletAddress) {
        const newWalletData = {
          walletAddress: metamaskWalletAddress,
          walletType: "METAMASK",
        };

        if (
          walletList.some(
            (wallet: any) => wallet.walletAddress == metamaskWalletAddress
          )
        ) {
          WarningToastify("이미 추가된 지갑주소입니다.");
        } else {
          setWalletList([...walletList, newWalletData]);
        }
      } else {
        return;
      }
    } catch (error) {
      // console.error("connectMetaMask 오류:", error);
    }
  };

  const connectKaikas = async () => {
    try {
      const kaikasWalletAddress = await ConnectKaikas();
      if (kaikasWalletAddress) {
        const newWalletData = {
          walletAddress: kaikasWalletAddress,
          walletType: "KAIKAS",
        };
        if (
          walletList.length === 1 &&
          Object.keys(walletList[0]).length === 0
        ) {
          setWalletList([newWalletData]);
        } else {
          if (
            walletList.some(
              (wallet: any) => wallet.walletAddress == kaikasWalletAddress
            )
          ) {
            WarningToastify("이미 추가된 지갑주소입니다.");
            return;
          } else {
            setWalletList([newWalletData]);
          }
          setWalletList([...walletList, newWalletData]);
        }
      } else {
        return;
      }
    } catch (error) {
      // console.error("connectKaikas 오류:", error);
    }
  };

  let selectedFile: any;
  let imgUrl: any;

  const requestImgUrl = async () => {
    try {
      const formData = new FormData();
      if (selectedFile !== null) {
        formData.append("image", selectedFile);
        const response = await axios.post(
          process.env.REACT_APP_SERVER_API + "/files/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.data.url) {
          imgUrl = response.data.data.url;
          setImageUrl(response.data.data.url);
          NotiToastify("이미지 업로드가 되었습니다.");
          setUserInfo({ ...userInfo, image: imgUrl });

          return { status: true, imgUrl: imgUrl };
        }
      }
    } catch (error) {
      NotiToastify("파일 업로드에 실패했습니다.");
      return false;
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    selectedFile = e.target.files?.[0] || null;
    if (selectedFile !== null) {
      const requestImgUrlResult = await requestImgUrl();
    }
  };

  const getLoginInfo = async () => {
    const res: any = await GetLoginUserInfo();
    if (res.status) {
      setUserInfo(res.data);
      setWalletList(res.data.walletList);
      const originWallet = res.data.walletList.map((item: any) => {
        return item.walletAddress;
      });
      setOriginWalletList(originWallet);
      setImageUrl(res.data.image);
    } else if (!res.status) {
      WarningToastify(res.message);
      RemoveLoginStatusInSession();
      window.location.href = "/";
    }
  };

  const filterNewWalletList = async () => {
    const newWalletArr: any = walletList.filter((wallet) => {
      return !originWalletList.includes(wallet.walletAddress);
    });
    setNewWalletList(newWalletArr);
  };

  const requestEditProfileInfo = async () => {
    await filterNewWalletList();

    const editData = {
      name: userInfo.name,
      image: imageUrl,
      walletList: newWalletList,
    };

    try {
      const url = process.env.REACT_APP_SERVER_API + "/users/profile";
      const res = await instance.patch(url, editData);
      if (res.data.status == 200) {
        NotiToastify("회원정보가 정상적으로 업데이트 되었습니다.");
        window.location.reload();
      }
    } catch (error: any) {
      if (error.response.data) {
        WarningToastify(<div>{error.response.data.message}</div>);
        return;
      }
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("login_user") !== null) {
      const userStatusString: any = sessionStorage.getItem("login_user");
      const userStatus = JSON.parse(userStatusString);
      if (userStatus.type == "USER") {
        getLoginInfo();
      } else {
        alert("접근 권한이 없습니다.");
        window.location.href = "/";
      }
    } else {
      alert("로그인을 먼저 진행해주세요");
      window.location.href = "/";

      return;
    }
  }, []);

  useEffect(() => {
    filterNewWalletList();
  }, [walletList]);

  return (
    <div className="flex items-center justify-center">
      <div className="flex flex-col items-center justify-center ">
        <img src={six_steps_logo} className="w-[100px] h-[82px] mt-[60px]" />
        <h3 className="text-gray100 mt-[12px]">회원 정보 수정</h3>
        <Modal
          content={
            <PfpModalContent
              setImgUrl={setImageUrl}
              setIsActivePfpModal={setIsActivePfpModal}
            />
          }
          isActiveModal={isActivePfpModal}
          closeModal={setIsActivePfpModal}
        />
        <div className="mt-[65px]">
          <div className="mb-[40px]">
            <LeftArrow fillColor="#868686" height={30} width={30} />
          </div>
          <div className="flex">
            <span className="text-gray100 w-[100px] font-semibold">이름</span>
            <input
              maxLength={10}
              type="text"
              className="border border-gray_input rounded-[30px] h-[26px] w-[160px] text-[12px] pl-5"
              placeholder="닉네임 입력"
              defaultValue={userInfo.name}
              onChange={(e: any) => {
                setUserInfo({ ...userInfo, name: e.target.value });
              }}
            />
          </div>
          <div className="flex mt-[40px] ">
            <span className="text-gray100 w-[100px] font-semibold ">
              프로필사진
            </span>
            <div className="relative">
              {imageUrl !== "" ? (
                <img
                  src={imageUrl}
                  className="sm:w-[300px] sm:h-[300px] "
                  alt="선택한 이미지"
                />
              ) : (
                <div className="sm:w-[300px] sm:h-[300px] bg-[#F7F7F7] text-gray100 flex items-center justify-center ">
                  이미지를 선택하세요
                </div>
              )}
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <button
                type="button"
                onClick={() => fileInputRef.current?.click()}
                className="absolute top-0 right-0 mt-[10px] mr-[6px] rounded-[30px] h-[27px] flex items-center justify-center bg-brand100 text-white w-[100px] text-center cursor-pointer"
              >
                <span className="mr-1 text-[12px]"> 파일 업로드</span>
                <UploadSVG fillColor="#ffffff" height={15} width={15} />
              </button>
            </div>
            <button
              type="button"
              className="bg-brand100 ml-6 text-white rounded-[20px] px-4 h-[33px] flex items-center justify-center text-[14px]'
              "
              onClick={() => {
                setIsActivePfpModal(true);
              }}
            >
              <span className="mr-1"> 프로필 랜덤 고르기</span>
            </button>
          </div>
          <div className="flex mt-[40px]">
            <span className="text-gray100 w-[100px] font-semibold">
              등록 지갑
            </span>
            <div className="flex flex-col">
              {walletList.length > 0 && (
                <>
                  {walletList.map((wallet: any, index) => (
                    <div key={index} className="flex">
                      {wallet.walletAddress && (
                        <div className="flex w-full my-2">
                          <input
                            type="text"
                            className=" border border-gray-300 rounded-[30px] h-[26px] text-[12px] flex items-center justify-center p-3 text-gray-500 w-full"
                            placeholder="지갑주소 입력"
                            value={wallet.walletAddress}
                            disabled
                          />
                          {originWalletList.includes(wallet.walletAddress) ? (
                            <div></div>
                          ) : (
                            <div
                              className="ml-3 rounded-full cursor-pointer border-[#f5d9c6] border-[2px] text-brand100 w-[26px] h-[26px]  flex items-center justify-center"
                              onClick={() => {
                                const updatedWalletList = [...walletList];
                                updatedWalletList.splice(index, 1);
                                setWalletList(updatedWalletList);
                              }}
                            >
                              <span className="text-[36px] pb-1">-</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}

              {/* 지갑 추가  */}
              <div className="flex space-x-3  mt-[40px] ">
                <button
                  type="button"
                  className="bg-metamask_color rounded-[10px] text-white h-[40px] px-8 flex items-center justify-center"
                  onClick={() => {
                    connectMetaMask();
                  }}
                >
                  <img src={metamask_logo} className="w-[20px] h-[20px]" />
                  <span className="ml-2">메타마스크 지갑 추가</span>
                </button>
                <button
                  type="button"
                  className="bg-kaikas_color rounded-[10px] text-white h-[40px] px-8 flex items-center justify-center"
                  onClick={() => {
                    connectKaikas();
                  }}
                >
                  <img src={kaikas_logo} className="w-[20px] h-[20px]" />
                  <span className="ml-2"> 카이카스 지갑 추가</span>
                </button>
              </div>
              {/*  */}
            </div>
          </div>
        </div>
        <div className="flex w-full items-center justify-center mt-[64px] space-x-3 mb-[120px]">
          <button
            type="button"
            className="rounded-[30px] border border-brand100 flex items-center justify-center text-brand100 w-[100px] h-[33px]"
            onClick={() => {
              window.history.back();
            }}
          >
            취소
          </button>
          <button
            type="button"
            className="rounded-[30px]  bg-brand100 flex items-center justify-center text-white w-[100px] h-[33px]"
            onClick={() => {
              requestEditProfileInfo();
            }}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserEditContent;
