import { useNavigate } from "react-router-dom";
import event_active_sample from "../../../common/images/event_main_sample1png.png";
import { useEffect, useRef, useState } from "react";
import GetEventList from "../../Feat/event/GetEventList";
import Pagination from "react-js-pagination";

const EventMain = () => {
  const navigate = useNavigate();
  const [eventList, setEventList]: any = useState([]);

  //page
  const [totalItem, setTotalItem] = useState(0);
  const [page, setPage] = useState(0); // 페이지 번호를 0부터 시작하도록 변경
  const itemsPerPage = 5;
  const handlePageChange = (page: number) => {
    setPage(page - 1);
  };
  const getEventListToServer = async () => {
    const res: any = await GetEventList(page);
    if (res.status) {
      setTotalItem(res.data.totalElements);
      setEventList(res.data.content);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getEventListToServer();
  }, [page]);

  return (
    <div className="flex items-center justify-center">
      <div className="flex flex-col  w-[70%]  my-[100px]  ">
        <div className="flex flex-col">
          <h2 className="text-[42px] text-brand100 font-semibold">EVENT</h2>
          <h4 className="text-gray100 text-[35px]">이벤트</h4>
        </div>

        <div className="w-full mt-[50px] flex-col">
          <div className="flex mt-[20px] relative flex-col">
            {eventList.map((event: any) =>
              event.status == "IN_PROGRESS" ? (
                <div key={event.eventId} className="flex mt-[40px] relative">
                  <div className="absolute h-[46px] top-[-24px] z-50 w-[130px] rounded-[30px] border-[3px] text-[20px] border-brand100 flex items-center justify-center text-brand100 bg-white font-semibold">
                    진행중
                  </div>
                  <img
                    src={event.bannerImage}
                    className="h-[280px] w-full cursor-pointer  "
                    onClick={() => {
                      navigate(`/event/detail/${event.eventId}`);
                    }}
                  />
                </div>
              ) : null
            )}
          </div>
          <div className="flex mt-[20px] relative flex-col">
            {eventList.map((event: any) =>
              event.status == "SCHEDULED" ? (
                <div key={event.eventId} className="flex mt-[40px] relative">
                  <div className="absolute h-[46px] top-[-24px] z-50 w-[130px] rounded-[30px] border-[3px] text-[20px] border-brand100 flex items-center justify-center text-brand100 bg-white font-semibold">
                    진행예정
                  </div>
                  <img
                    src={event.bannerImage}
                    className="h-[280px] w-full cursor-pointer  "
                    onClick={() => {
                      navigate(`/event/detail/${event.eventId}`);
                    }}
                  />
                </div>
              ) : null
            )}
          </div>

          <div className="flex mt-[80px] relative flex-col">
            {eventList.map((event: any) =>
              event.status === "COMPLETED" ? (
                <div key={event.eventId} className="flex mt-[40px] relative">
                  <div className="absolute h-[46px] top-[-24px] z-50 w-[130px] rounded-[30px] border-[3px] text-[20px] border-gray100 flex items-center justify-center text-gray100 bg-white font-semibold">
                    종료
                  </div>
                  <img
                    src={event.bannerImage}
                    className="h-[280px] w-full cursor-pointer  "
                    onClick={() => {
                      navigate(`/event/detail/${event.eventId}`);
                    }}
                  />
                </div>
              ) : null
            )}
          </div>
          <div className="w-full items-center justify-center flex mt-[80px]">
            <Pagination
              activePage={page + 1}
              itemsCountPerPage={itemsPerPage}
              totalItemsCount={totalItem}
              pageRangeDisplayed={5}
              onChange={handlePageChange}
              itemClass="inline-block mx-1 text-[22px]  font-semibold  px-2 py-1 rounded-lg cursor-pointer mb-[80px]"
              activeClass=" text-brand100 text-[22px]  font-semibold"
              firstPageText={<div className="text-brand100">&#171;</div>}
              lastPageText={<div className="text-brand100">&#187;</div>}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventMain;
