import MainIntroduceSlide from "./MainIntroduceSlide";
import MainIntroduceRightSection from "./MainIntroduceRightSection";

const IntroduceContainer = () => {
  return (
    <div className="main-intro-container">
      <MainIntroduceSlide />
      <MainIntroduceRightSection />
    </div>
  );
};

export default IntroduceContainer;
