import { useNavigate, useParams } from "react-router-dom";
import LeftArrow from "../../../../common/svg/LeftArrow";
import event_active_sample from "../../../../common/images/event_main_sample1png.png";
import GetEventDetailInfo from "../../../Feat/event/GetEventDetailInfo";
import { useEffect, useState } from "react";
import WarningToastify from "../../common/WarningToastify";

const EventDetail = () => {
  const { id }: any = useParams();
  const [eventDetail, setEventDetail]: any = useState({});

  const getDetailInfo = async () => {
    const res: any = await GetEventDetailInfo(id);
    if (res.status) {
      setEventDetail(res.data);
    } else {
      WarningToastify("오류가 발생했습니다. 관리자에게 문의해주세요.");
      window.location.href = "/event";
    }
  };

  useEffect(() => {
    getDetailInfo();
  }, []);
  return (
    <div className="flex items-center justify-center w-full">
      <div className="flex flex-col w-[65%] mt-[40px]">
        <div className="w-full mb-[20px]  cursor-pointer">
          <LeftArrow width={34} height={34} fillColor="#868686" />
        </div>
        <img
          src={eventDetail.bannerImage}
          className="h-[280px] w-full cursor-pointer"
        />
        <h3 className="text-brand100 text-[32px] mt-[30px]">
          {eventDetail.name}
        </h3>
        <hr className="w-full border-[1.8px] border-brand100 my-[20px]" />
        <div className="flex flex-col items-center justify-center mb-[40%]">
          <h3>{eventDetail.description} </h3>
          <img
            src={eventDetail.mainImage}
            className="h-[280px] w-[280px]  cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
};

export default EventDetail;
