import { useState } from "react";
import RightArrow from "../../../../common/svg/RightArrow";
import { useRecoilState } from "recoil";
import { searchMapKeywordState } from "../../../../utils/recoil/kakaomap/atom";
import { Trans, useTranslation } from "react-i18next";

const SearchMapContent = () => {
  const [searchKeyword, setSearchKeyword] = useRecoilState(
    searchMapKeywordState
  );
  const [typingKeyword, setTypingKeyword] = useState("");
  const searchKeywordAction = () => {
    setSearchKeyword(typingKeyword);
  };
  const { t, i18n } = useTranslation();

  return (
    <div className="sm:w-[600px] flex flex-col  ">
      <div className="flex flex-col   mb-20">
        <div className="text-[40px] font-bold tracking-normal">
          <Trans>{t("page:main-map-title")}</Trans>
        </div>
        <div className="text-[24px] mt-10 font-semibold">
          <Trans>{t("page:main-map-content")}</Trans>
        </div>
        <div>
          <div className="relative sm:w-[70%] mt-5  ">
            <div className=" absolute inset-y-0  left-0 flex items-center pl-3 ">
              <svg
                className="w-4 h-4 text-[#E06611] "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              type="search"
              className="w-full p-4 pl-10 text-sm  text-gray40 border-b-[2px] border-[#FFD4BA] border-t-0 border-x-0  bg-white bg-opacity-0 "
              placeholder={`${
                i18n.language == "en"
                  ? "ex) Gangnam-gu, Seoul, Republic of Korea"
                  : "서울특별시 강남구 테헤란로"
              }`}
              onChange={(e) => {
                setTypingKeyword(e.target.value);
              }}
            />

            <button
              type="submit"
              className="text-white absolute right-2.5 bottom-2.5 bg-[#E06611] font-medium rounded-[30px] text-sm px-4 py-2"
              onClick={() => {
                searchKeywordAction();
              }}
            >
              <Trans>{t("page:main-search-button")}</Trans>
            </button>
          </div>
        </div>
      </div>
      {/* <button

        type="button"
        className="sm:mt-[90px] mt-[50px] sm:mb-0 mb-[40px] flex items-center justify-center rounded-[20px] text-white bg-[#E06611] text-[18px] w-fit px-5 h-[38px]"
      >
        <span
          className="mr-1"
          onClick={() => {
            window.location.href = "/nftList";
          }}
        >
          <Trans>{t("page:main-map-interest-button")}</Trans>
        </span>
        <RightArrow width={18} height={18} fillColor="#ffffff" />
      </button> */}
    </div>
  );
};

export default SearchMapContent;
