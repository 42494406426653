import AdoptRegisterNFT from "../../../components/UI/nftUpload/adopt/AdoptRegisterNFT";

const AdoptNFTPage = () => {
  return (
    <div>
      <AdoptRegisterNFT />
    </div>
  );
};

export default AdoptNFTPage;
