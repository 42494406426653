import React from "react";
import UserEditContent from "../../../../components/UI/mypage/user/edit/UserEditContent";

const UserEditPage = () => {
  return (
    <div>
      <UserEditContent />
    </div>
  );
};

export default UserEditPage;
