import { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import MyNftDetailCard from "./nftDetail/MyNftDetailCard";
import { useNavigate } from "react-router-dom";
import CheckCurrentNetwork from "../../../Feat/network/CheckCurrentNetwork";
import MatchChainIdAndNetworkName from "../../../Feat/network/MatchChainIdAndNetworkName";
import instance from "../../../Feat/axios/instance";
import GetLoginAgencyInfo from "../../../Feat/agency/GetLoginAgencyInfo";
import WarningToastify from "../../common/WarningToastify";
import RemoveLoginStatusInSession from "../../../Feat/auth/RemoveLoginStatusInSession";
import CheckCurrentKlaytnNetwork from "../../../Feat/wallet/klaytn/CheckCurrentKlaytnNetwork";
import KaikasMatchChainIdAndNetworkName from "../../../Feat/network/klaytn/KaikasMatchChainIdAndNetworkName";
import SoldOutCard from "./nftDetail/SoldOutCard";

const MyPageContent = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0); // 페이지 번호를 0부터 시작하도록 변경
  const itemsPerPage = 12;
  const handlePageChange = (selectedPage: number) => {
    setPage(selectedPage - 1);
  };

  const [filterType, setFilterType] = useState("ALL");
  const [userWallet, setUserWallet] = useState("");
  const [networkName, setNetworkName] = useState("");
  const [myDataList, setMyDataList] = useState<ItemType[]>([]);
  const [totalItem, setTotalItem] = useState(0);
  const [userInfo, setUserInfo] = useState([]);

  const updateUserData = async () => {
    const userStatusString: any = sessionStorage.getItem("login_user");
    const userStatus = JSON.parse(userStatusString);

    setUserWallet(userStatus.loginAddress);

    if (userStatus.walletType == "METAMASK") {
      const currentNetwork: any = await CheckCurrentNetwork();
      const currentNetworkName: any =
        MatchChainIdAndNetworkName(currentNetwork);
      if (!currentNetworkName) {
        setNetworkName("제공하는 서비스 네트워크가 아닙니다.");
        return;
      }
      setNetworkName(currentNetworkName);
    } else if (userStatus.walletType == "KAIKAS") {
      const res: any = await CheckCurrentKlaytnNetwork();
      const currentNetworkName: any = KaikasMatchChainIdAndNetworkName(
        res.chainId
      );
      setNetworkName(currentNetworkName);
    } else if (userStatus.walletType == "WHATCON") {
      setNetworkName("왓콘 체인");
    }
  };

  const getUserNFTList = async () => {
    try {
      const url =
        process.env.REACT_APP_SERVER_API +
        `/agency/nfts?status=${filterType}&page=${page}&size=${12}`;

      const response = await instance.get(url, {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      });
      if (response.data) {
        setMyDataList(response.data.data.content);
        const totalElements = response.data.data.totalElements;
        setTotalItem(totalElements);
      } else {
        setMyDataList([]);
      }
    } catch (error: any) {
      if (error.response.data.message) {
        WarningToastify(error.response.data.message);
        return;
      } else {
        alert("알 수 없는 에러가 발생했습니다.");
      }
    }
  };

  const handleFilterClick = (filter: string) => {
    if (filter === "전체") {
      setFilterType("ALL");
    } else if (filter === "판매중") {
      setFilterType("ON_SALE");
    } else if (filter === "판매예정") {
      setFilterType("FOR_SALE");
    } else if (filter === "판매완료") {
      setFilterType("SOLD_OUT");
    } else if (filter === "구매목록") {
      setFilterType("PURCHASE");
    } else if (filter === "좋아요") {
      setFilterType("LIKE");
    }
  };

  const getLoginInfo = async () => {
    const res: any = await GetLoginAgencyInfo();
    if (res.status) {
      setUserInfo(res.data);
    } else if (!res.status) {
      WarningToastify(res.message);
      RemoveLoginStatusInSession();
      window.location.href = "/";
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("login_user")) {
      updateUserData();
      getUserNFTList();
      getLoginInfo();
    } else {
      WarningToastify("로그인을 먼저 진행해주세요.");
      window.location.href = "/";
      return;
    }
  }, [filterType, page]); // 필터 또는 페이지 번호가 변경될 때마다 데이터 다시 가져오도록 변경

  return (
    <div className="flex items-center justify-center ">
      <div className="flex flex-col  mt-[80px]  max-w-[calc(1280px+60px)] ">
        <div className="flex sm:content-between sm:justify-between w-full sm:flex-row flex-col">
          <div className="flex flex-col">
            <h3 className="text-brand100 text-[32px] font-semibold">MY PAGE</h3>
            <h3 className="text-gray100 text-[20px] ">마이페이지</h3>
          </div>
          <button
            type="button"
            onClick={() => {
              navigate("/agency/mypage/edit");
            }}
            className="mypage-mydata-edit-button"
          >
            내정보 수정
          </button>
        </div>
        <div className="flex mt-[70px] sm:flex-row flex-col">
          <div className="sm:flex hidden flex-col  ">
            <h3 className="text-brand100 font-bold">로그인 지갑주소 </h3>
            <h3 className="text-gray100 mt-2">{userWallet}</h3>
          </div>

          <div className="mypage-connect-network">
            <h3 className="text-brand100  font-bold">연결된 네트워크 </h3>
            <h3 className="text-gray100 mt-1">{networkName}</h3>
          </div>
        </div>

        <div className="sm:flex hidden flex-col mt-[80px] ">
          <div className="flex space-x-2">
            <button
              type="button"
              className={`${
                filterType === "ALL"
                  ? "bg-[#f5d9c6]"
                  : "border border-[#f5d9c6]"
              } text-brand100 w-[94px] h-[30px] rounded-[30px]`}
              onClick={() => handleFilterClick("전체")}
            >
              전체
            </button>
            <button
              type="button"
              className={`${
                filterType === "ON_SALE"
                  ? "bg-[#f5d9c6]"
                  : "border border-[#f5d9c6]"
              } text-brand100 w-[94px] h-[30px] rounded-[30px]`}
              onClick={() => handleFilterClick("판매중")}
            >
              판매중
            </button>
            <button
              type="button"
              className={`${
                filterType === "FOR_SALE"
                  ? "bg-[#f5d9c6]"
                  : "border border-[#f5d9c6]"
              } text-brand100 w-[94px] h-[30px] rounded-[30px]`}
              onClick={() => handleFilterClick("판매예정")}
            >
              판매예정
            </button>
            <button
              type="button"
              className={`${
                filterType === "SOLD_OUT"
                  ? "bg-[#f5d9c6]"
                  : "border border-[#f5d9c6]"
              } text-brand100 w-[94px] h-[30px] rounded-[30px]`}
              onClick={() => handleFilterClick("판매완료")}
            >
              판매완료
            </button>
            <button
              type="button"
              className={`${
                filterType === "PURCHASE"
                  ? "bg-[#f5d9c6]"
                  : "border border-[#f5d9c6]"
              } text-brand100 w-[94px] h-[30px] rounded-[30px]`}
              onClick={() => handleFilterClick("구매목록")}
            >
              구매목록
            </button>
            <button
              type="button"
              className={`${
                filterType === "LIKE"
                  ? "bg-[#f5d9c6]"
                  : "border border-[#f5d9c6]"
              } text-brand100 w-[94px] h-[30px] rounded-[30px]`}
              onClick={() => handleFilterClick("좋아요")}
            >
              좋아요
            </button>
          </div>
        </div>
        {myDataList.length == 0 && (
          <div className="flex min-h-screen items-center justify-center sm:min-w-[1020px] text-brand100">
            * 나의 데이터가 없습니다.
          </div>
        )}
        {myDataList.length !== 0 && filterType !== "SOLD_OUT" && (
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4  lg:min-w-[1120px] md:min-w-[860px] sm:min-w-[680px] gap-7 min-h-screen mb-[80px]">
            {myDataList.map((item, index) => (
              <div
                className="mb-4 h-fit transform transition-transform hover:scale-105 "
                key={index}
              >
                <MyNftDetailCard item={item} />
              </div>
            ))}
          </div>
        )}

        {myDataList.length !== 0 && filterType == "SOLD_OUT" && (
          <div className="w-full  grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  lg:grid-cols-4  lg:min-w-[1120px] md:min-w-[860px] sm:min-w-[680px] gap-7 min-h-screen mb-[80px]">
            {myDataList.map((item, index) => (
              <div
                className="mb-4 h-fit transform transition-transform hover:scale-105 "
                key={index}
              >
                <SoldOutCard item={item} />
              </div>
            ))}
          </div>
        )}

        {/* 페이지네이션 */}
        <div className="flex w-full items-center justify-center">
          {/* Pagination 컴포넌트에 tailwind CSS 클래스 추가 */}
          <Pagination
            activePage={page + 1}
            itemsCountPerPage={itemsPerPage}
            totalItemsCount={totalItem} // totalPages를 사용합니다.
            pageRangeDisplayed={5}
            onChange={handlePageChange}
            itemClass="inline-block mx-1 text-[22px] font-semibold   px-2 py-1 rounded-lg cursor-pointer mb-[80px] "
            activeClass=" text-brand100 text-[22px] font-semibold "
            disabledClass="text-[#9f9f9f]  "
            hideFirstLastPages={true}
            innerClass="text-[#9f9f9f]"
            prevPageText={<div className="text-brand100">&#60;</div>}
            nextPageText={<div className="text-brand100">&#62;</div>}
            firstPageText={<div className="text-brand100">&#171;</div>}
            lastPageText={<div className="text-brand100">&#187;</div>}
          />
        </div>
      </div>
    </div>
  );
};

export default MyPageContent;
