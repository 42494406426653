import axios from "axios";
import WarningToastify from "../../UI/common/WarningToastify";

const GetEventDetailInfo = async (eventId: number) => {
  try {
    const url =
      process.env.REACT_APP_SERVER_API + "/admin/events/info/" + eventId;
    const response = await axios.get(url);
    if (response.status == 200) {
      return { status: true, data: response.data.data };
    } else {
      return {
        status: false,
        message: "오류가 발생했습니다. 관리자에게 문의해주세요.",
      };
    }
  } catch (error: any) {
    if (error.response.data.message) {
      WarningToastify(error.response.data.message);
      return;
    }
  }
};

export default GetEventDetailInfo;
