import { useEffect, useRef, useState } from "react";
import {
  PaymentWidgetInstance,
  loadPaymentWidget,
  ANONYMOUS,
} from "@tosspayments/payment-widget-sdk";
import { nanoid } from "nanoid";
import CloseSVG from "../../../common/svg/CloseSVG";

const selector = "#payment-widget";

const clientKey = "test_ck_4yKeq5bgrpwdw1OZ24XZVGX0lzW6";
// const customerKey = "YbX2HuSlsC9uVJW6NMRMj";

const PaymentModalContent = (props: any) => {
  const { price } = props;
  const { nftId } = props;
  const { auctionNum } = props;
  const { saleId } = props;

  const paymentWidgetRef = useRef<PaymentWidgetInstance | null>(null);
  const paymentMethodsWidgetRef = useRef<ReturnType<
    PaymentWidgetInstance["renderPaymentMethods"]
  > | null>(null);

  useEffect(() => {
    (async () => {
      const paymentWidget = await loadPaymentWidget(clientKey, ANONYMOUS);
      const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
        selector,
        { value: price }
      );

      paymentWidget.renderAgreement("#agreement");
      paymentWidgetRef.current = paymentWidget;
      paymentMethodsWidgetRef.current = paymentMethodsWidget;
    })();
  }, []);

  useEffect(() => {
    const paymentMethodsWidget: any = paymentMethodsWidgetRef.current;
    if (paymentMethodsWidget == null) {
      return;
    }
    paymentMethodsWidget.updateAmount(price);
  }, [price]);
  return (
    <div className="w-[580px]">
      <div
        className="w-fulll items-end justify-end flex cursor-pointer "
        onClick={() => {
          window.location.href = "/nftDetail/" + nftId;
        }}
      >
        <CloseSVG fillColor="#E5E8EB" width={35} height={30} />
      </div>

      <div>
        <div className="flex flex-col px-[25px] ">
          <h1 className="text-[18px] font-semibold ">결제 금액</h1>
          <span className="text-[16px]">{`${price.toLocaleString()} 원`}</span>
        </div>

        <div id="payment-widget" />
        <div id="agreement" />
        <div className="w-full items-center justify-center flex">
          <button
            className="bg-[#1A4AC5] rounded-[10px] text-white p-2 w-[120px] "
            onClick={async () => {
              const paymentWidget = paymentWidgetRef.current;

              try {
                await paymentWidget?.requestPayment({
                  orderId: nanoid(),
                  orderName: "토스 티셔츠 외 2건",
                  customerName: "김토스",
                  customerEmail: "customer123@gmail.com",
                  successUrl: `${window.location.origin}/payment/success?nftId=${nftId}&saleId=${saleId}&auctionNum=${auctionNum}&`,
                  failUrl: `${window.location.origin}/payment/fail`,
                });
              } catch (error) {
                console.error(error);
              }
            }}
          >
            결제하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentModalContent;
