import EmailRegister from "../../../components/UI/auth/register/personal/EmailRegister";

const EmailRegisterPage = () => {
  return (
    <div>
      <EmailRegister />
    </div>
  );
};

export default EmailRegisterPage;
