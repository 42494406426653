const ConnectKaikas = async () => {
  try {
    if (!window.klaytn) {
      alert("카이카스 지갑을 먼저 설치해주세요");
      return;
    } else {
      const wallet = await window.klaytn.enable();
      if (wallet[0].length !== 0) {
        sessionStorage.setItem("user_connect_wallet", "KAIKAS");
        return wallet[0];
      }
    }
  } catch (error) {
    console.error("카이카스 연결 에러:", error);
  }
};

export default ConnectKaikas;
