import React from "react";

const CloseSVG: React.FC<SvgProps> = ({ fillColor, width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fillColor}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Menu / Close_SM">
        <path
          id="Vector"
          d="M16 16L12 12M12 12L8 8M12 12L16 8M12 12L8 16"
          stroke="#000000"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default CloseSVG;
