import NoticeContainer from "../../components/UI/notice/NoticeContainer";

const NoticePage = () => {
  return (
    <div>
      <NoticeContainer />
    </div>
  );
};

export default NoticePage;
