import { useNavigate } from "react-router-dom";
import six_steps_logo from "../../../../common/images/6_steps.jpeg";

const RegisterSuccessContent = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center mt-[10%] ">
        <img src={six_steps_logo} className="w-[120px] h-[93px] " />
        <div className="flex flex-col items-center justify-center text-[#e06612]">
          <h3 className="text-[24px] font-semibold mt-[40px]">
            회원가입이 완료되었습니다.
          </h3>
          <h3 className="mt-[10px] mb-[60px]">
            6 STEPS에서 즐거운 추억 만드시기 바랍니다.
          </h3>
        </div>

        <button
          type="button"
          className="bg-brand100 rounded-[10px] text-white h-[46px] w-[220px]"
          onClick={() => {
            navigate("/");
          }}
        >
          로그인하러 가기
        </button>
      </div>
    </div>
  );
};

export default RegisterSuccessContent;
