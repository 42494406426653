import { ethers } from "ethers";
import CheckCurrentNetwork from "../network/CheckCurrentNetwork";
import MatchChainIdAndRouterAdd from "../network/MatchChainIdAndRouterAdd";
import MatchChainIdAndAuction from "../network/MatchChainIdAndAuction";
import SendWithdrawInfoToServer from "./SendWithdrawInfoToServer";
import WarningToastify from "../../UI/common/WarningToastify";
import MatchChainIdAndNetworkName from "../network/MatchChainIdAndNetworkName";

const WithdrawNFT = async (auctionNum: number, saleId: number) => {
  try {
    let provider = new ethers.BrowserProvider(window.ethereum);
    let signer = await provider.getSigner();

    const currentNetwork = await CheckCurrentNetwork();
    const currentNetworkName: any = MatchChainIdAndNetworkName(currentNetwork);

    const router: any = await MatchChainIdAndRouterAdd(currentNetwork);
    const routerContractAbi = require("../../../utils/contract/MarketplaceRouter.json");
    const routerContract = new ethers.Contract(
      router,
      routerContractAbi.abi,
      signer
    );

    const platform: any = MatchChainIdAndAuction(currentNetwork);

    const param = {
      Platform: platform,
      Index: auctionNum,
    };

    const withdraw = await routerContract.withdraw(param.Platform, param.Index);
    const result = await withdraw.wait();
    const sellerWalletAddress = signer.address;
    const res = await SendWithdrawInfoToServer(saleId);

    if (res) {
      return true;
    } else {
      WarningToastify("NFT 출금 정보 전송에 실패했습니다.");
      return;
    }
  } catch (error: any) {
    if (error.code == "ACTION_REJECTED") {
      alert("취소가 거부되었습니다.");
      window.location.reload();
      return;
    } else {
      WarningToastify("에러가 발생했습니다.");
      window.location.reload();
      return;
    }
  }
};

export default WithdrawNFT;
