import WarningToastify from "../../UI/common/WarningToastify";
import instance from "../axios/instance";

const UpdateWalletAddressList = async (userType: string) => {
  if (userType == "ROLE_AGENCY") {
    try {
      const url = process.env.REACT_APP_SERVER_API + "/agency/profile";
      const res = await instance.get(url);
      if (res.data.status == 200) {
        const walletList = res.data.data.walletList;
        sessionStorage.setItem("loginWalletList", JSON.stringify(walletList));
      }
    } catch (error: any) {
      if (error.response.data.message) {
        WarningToastify(error.response.data.message);
        return;
      } else {
        alert("알 수 없는 에러가 발생했습니다.");
      }
    }
  } else if (userType == "ROLE_USER") {
    try {
      const url = process.env.REACT_APP_SERVER_API + "/users/profile";
      const res = await instance.get(url);
      if (res.data.status == 200) {
        const walletList = res.data.data.walletList;
        sessionStorage.setItem("loginWalletList", JSON.stringify(walletList));
      }
    } catch (error: any) {
      if (error.response.data.message) {
        WarningToastify(error.response.data.message);
        return;
      } else {
        alert("알 수 없는 에러가 발생했습니다.");
      }
    }
  }
};

export default UpdateWalletAddressList;
