import Lottie from "react-lottie-player";
// Alternatively:
// import Lottie from 'react-lottie-player/dist/LottiePlayerLight'

import loadingJson from "./loading_animation.json";

export default function LoadingLottie() {
  return (
    <Lottie
      loop
      animationData={loadingJson}
      play
      style={{ width: 120, height: 120 }}
    />
  );
}
