import instance from "../axios/instance";

const SendBuyNFTInfoToServer = async (data: any) => {
  const sendToServerData = {
    buyerWalletAddress: data.buyerWalletAddress,
    buyTransactionHash: data.buyTransactionHash,
    network: data.network,
  };

  try {
    const url = process.env.REACT_APP_SERVER_API + "/nfts/buy/" + data.saleId;
    const response = await instance.post(url, sendToServerData, {
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    return true;
  } catch (error: any) {
    // console.log("error", error);
    return false;
  }
};

export default SendBuyNFTInfoToServer;
