import NoticeDetailContainer from "../../../components/UI/notice/detail/NoticeDetailContainer";

const NoticeDetailPage = () => {
  return (
    <div>
      <NoticeDetailContainer />
    </div>
  );
};

export default NoticeDetailPage;
