import React from "react";
import NftContainer from "../../components/UI/nftList/NftContainer";

const NftListPage = () => {
  return (
    <div>
      <NftContainer />
    </div>
  );
};

export default NftListPage;
