import React, { useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { searchMapKeywordState } from "../../../../utils/recoil/kakaomap/atom";
import axios from "axios";
import WarningToastify from "../../common/WarningToastify";

const { kakao } = window;

const KakaoMap: React.FC = () => {
  const mapRef = useRef(null);
  const [searchKeyword, setSearchKeyword] = useRecoilState(
    searchMapKeywordState
  );
  const placesService = useRef(new kakao.maps.services.Places());
  const [nearestCenters, setNearestCenters] = useState<any[]>([]);
  const [watchCenter, setWatchCenter] = useState("");
  const [currentPosition, setCurrentPosition]: any = useState(null);

  const displaySearchResultsOnMap = (lat: any, lng: any) => {
    var mapContainer = mapRef.current;
    var mapOption = {
      center: new kakao.maps.LatLng(lat, lng),
      level: 3, // 지도의 확대 레벨
    };

    var map = new kakao.maps.Map(mapContainer, mapOption);
    var positions = nearestCenters.map((center: any) => ({
      title: center.name,
      latlng: new kakao.maps.LatLng(center.lat, center.lng),
    }));

    // 마커 이미지의 이미지 주소입니다
    var imageSrc =
      "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/markerStar.png";

    for (var i = 0; i < positions.length; i++) {
      // 마커 이미지의 이미지 크기 입니다
      var imageSize = new kakao.maps.Size(24, 35);
      var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize);
      var marker = new kakao.maps.Marker({
        map: map, // 마커를 표시할 지도
        position: positions[i].latlng, // 마커를 표시할 위치
        title: positions[i].title, // 마커의 타이틀, 마커에 마우스를 올리면 타이틀이 표시됩니다
        image: markerImage, // 마커 이미지
      });
    }
  };
  const markerNearByCenter = (centerList: any) => {
    if (mapRef.current) {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLat = position.coords.latitude;
            const userLng = position.coords.longitude;

            // 지도 생성 시 사용자의 현재 위치를 중심으로 설정
            var mapContainer = mapRef.current;
            var mapOption = {
              center: new kakao.maps.LatLng(userLat, userLng), // 사용자의 현재 위치를 중심으로 설정
              level: 3, // 지도의 확대 레벨
            };

            var map = new kakao.maps.Map(mapContainer, mapOption);
            var positions = centerList.map((center: any) => ({
              title: center.name,
              latlng: new kakao.maps.LatLng(center.lat, center.lng),
            }));

            // 마커 이미지의 이미지 주소입니다
            var imageSrc =
              "https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/markerStar.png";

            for (var i = 0; i < positions.length; i++) {
              // 마커 이미지의 이미지 크기 입니다
              var imageSize = new kakao.maps.Size(24, 35);
              var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize);
              var marker = new kakao.maps.Marker({
                map: map, // 마커를 표시할 지도
                position: positions[i].latlng, // 마커를 표시할 위치
                title: positions[i].title, // 마커의 타이틀, 마커에 마우스를 올리면 타이틀이 표시됩니다
                image: markerImage, // 마커 이미지
                clickable: true,
              });
            }
          },
          (error) => {
            // console.error("사용자 위치를 가져오지 못했습니다:", error);
          }
        );
      } else {
        console.error("Geolocation을 지원하지 않는 브라우저입니다.");
      }
    }
  };

  const getNearestCenters = async () => {
    try {
      const url = process.env.REACT_APP_SERVER_API + "/agency/coordinates";
      const response = await axios.get(url);
      if (response.status == 200) {
        const centerList = response.data.data.map((center: any) => ({
          name: center.name,
          lat: center.latitude,
          lng: center.longitude,
        }));

        setNearestCenters(centerList);
        markerNearByCenter(centerList);
      }
    } catch (error: any) {
      if (error.response.data.message) {
        WarningToastify(error.response.data.message);
        return;
      } else {
        alert("알 수 없는 에러가 발생했습니다.");
      }
    }
  };

  const handleListItemClick = (center: any) => {
    displaySearchResultsOnMap(center.lat, center.lng);
  };

  useEffect(() => {
    if (searchKeyword) {
      placesService.current.keywordSearch(
        searchKeyword,
        (data: any, status: any) => {
          if (data[0]) {
            if (status === kakao.maps.services.Status.OK) {
              const lat = data[0].y;
              const lng = data[0].x;
              displaySearchResultsOnMap(lat, lng);
            }
          } else {
            WarningToastify("검색된 결과가 없습니다.");
            return;
          }
        }
      );
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (mapRef.current) {
      const container = mapRef.current;
      const options = {
        center: new window.kakao.maps.LatLng(37.559070173067, 126.945031304044),
        level: 2,
      };
      const kakaoMap = new window.kakao.maps.Map(container, options);
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const lat = position.coords.latitude;
            const lng = position.coords.longitude;
            const userPosition = new kakao.maps.LatLng(lat, lng);
            kakaoMap.setCenter(userPosition);
            getNearestCenters();
          },
          (error) => {
            // console.error("사용자 위치를 가져오지 못했습니다:", error);
            getNearestCenters();
          }
        );
      } else {
      }
    }
  }, []);

  return (
    <div>
      <section className="">
        <article ref={mapRef} className="w-[689px] h-[465px]"></article>
      </section>
      <div className="flex content-between justify-between">
        <div className="mt-2 ">
          현재 보고있는 기관 :
          <span className="text-brand100 ml-1">{watchCenter}</span>
        </div>

        <div className="register-list-body ">
          <h3 className="font-semibold mb-[10px] text-[18px] text-brand100 ">
            등록된 보호센터 리스트
          </h3>
          {nearestCenters.map((center, index) => (
            <h3
              className="cursor-pointer hover:text-brand100 text-[16px] my-1"
              key={index}
              onMouseOver={() => {
                handleListItemClick(center);
                setWatchCenter(center.name);
              }}
              onMouseOut={() => {}}
              onClick={() => {
                handleListItemClick(center);
                setWatchCenter(center.name);
              }}
            >
              {center.name}
            </h3>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KakaoMap;
