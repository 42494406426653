import EditPage from "../../../../components/UI/mypage/agency/edit/EditPage";

const MyPageEdit = () => {
  return (
    <div>
      <EditPage />
    </div>
  );
};

export default MyPageEdit;
