import axios from "axios";

const MintPayment = async (ipfsURL: string, data: any) => {
  const url: string = process.env.REACT_APP_BLOCKCHAIN_API + "/api/mintNFT";
  const userStatusString: any = sessionStorage.getItem("login_user");
  const userStatus = JSON.parse(userStatusString);

  try {
    const res: any = await axios.post(url, {
      privateKey: userStatus.privateKey,
      metadata_uri: ipfsURL,
    });

    const updatedData = {
      ...data,
      tokenId: res.data.tokenID,
      mintingTransactionHash: res.data.transactionHash,
    };
    return { data: updatedData };
  } catch (error: any) {}
};

export default MintPayment;
