const CheckValidationServiceChain = (chainId: string) => {
  console.log("chainId:", chainId);
  if (chainId == "0xaa36a7") {
    return "SEPOLIA";
  } else if (chainId == "0x152") {
    return "CRONOS";
  } else if (chainId == "0x61") {
    return "BNB";
  } else if (chainId == "0x2019") {
    return "KLAYTN";
  } else {
    return false;
  }
};

export default CheckValidationServiceChain;
