import { useEffect, useRef, useState } from "react";
import six_steps_logo from "../../../../../common/images/6_steps.jpeg";
import ConnectKaikas from "../../../../Feat/wallet/klaytn/ConnectKaikas";
import { ConnectMetaMask } from "../../../../Feat/wallet/ConnectMetaMask";
import metamask_logo from "../../../../../common/images/metamask2x.png";
import kaikas_logo from "../../../../../common/images/kaikas_logo.png";
import axios from "axios";
import NotiToastify from "../../../common/NotiToastify";
import UploadSVG from "../../../../../common/svg/UploadSVG";
import Modal from "../../../common/Modal";
import SearchAddressModalContent from "./SearchAddressModalContent";
import WarningToastify from "../../../common/WarningToastify";
import PfpModalContent from "../common/PfpModalContent";

const InstitutionRegister = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [businessLicenseNum, setBusinessLicenseNum] = useState("");
  const [name, setName] = useState("");
  const [agencyType, setAgencyType] = useState("RIGHTS_GROUP");
  const [walletList, setWalletList] = useState<{ walletAddress?: string }[]>([
    {},
  ]);
  const [file, setFile] = useState<File | null>(null);

  const [isActiveSearchAddressPopUp, setIsActiveSearchAddressPopUp] =
    useState(false);
  const [address, setAddress] = useState<string>("");
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [imgUrl, setImgUrl] = useState("");
  const [isActivePfpModal, setIsActivePfpModal] = useState(false);

  let selectedFile: any;

  const handleBusinessLicenseNumChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = e.target.value.replace(/\D/g, "");

    if (inputValue.length >= 10) {
      const formattedValue = inputValue
        .slice(0, 10)
        .replace(/(\d{3})(\d{2})(\d{5})/, "$1-$2-$3");
      setBusinessLicenseNum(formattedValue);
    } else {
      setBusinessLicenseNum(inputValue);
    }
  };

  const requestImgUrl = async () => {
    try {
      const formData = new FormData();
      if (selectedFile !== null) {
        formData.append("image", selectedFile);
        const response = await axios.post(
          process.env.REACT_APP_SERVER_API + "/files/upload",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // 파일 업로드 성공 시 응답 데이터를 사용할 수 있습니다.
        if (response.data.data.url) {
          setImgUrl(response.data.data.url);
          NotiToastify("이미지 업로드가 되었습니다.");
          return true;
        }
      }
    } catch (error) {
      NotiToastify("파일 업로드에 실패했습니다.");
      return false;
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    selectedFile = e.target.files?.[0] || null;
    setFile(selectedFile);

    if (selectedFile !== null) {
      const requestImgUrlResult = await requestImgUrl();
    }
  };

  const connectMetaMask = async () => {
    try {
      const metamaskWalletAddress = await ConnectMetaMask();
      if (metamaskWalletAddress) {
        const newWalletData = {
          walletAddress: metamaskWalletAddress,
          walletType: "METAMASK",
        };

        if (
          walletList.length === 1 &&
          Object.keys(walletList[0]).length === 0
        ) {
          setWalletList([newWalletData]);
        } else {
          if (
            walletList.some(
              (wallet) => wallet.walletAddress === metamaskWalletAddress
            )
          ) {
            WarningToastify("이미 추가된 지갑주소입니다.");
          } else {
            setWalletList([...walletList, newWalletData]);
          }
        }
      } else {
        return;
      }
    } catch (error) {
      console.error("connectMetaMask 오류:", error);
    }
  };

  const connectKaikas = async () => {
    try {
      const kaikasWalletAddress = await ConnectKaikas();
      if (kaikasWalletAddress) {
        const newWalletData = {
          walletAddress: kaikasWalletAddress,
          walletType: "KAIKAS",
        };
        if (
          walletList.length === 1 &&
          Object.keys(walletList[0]).length === 0
        ) {
          setWalletList([newWalletData]);
        } else {
          if (
            walletList.some(
              (wallet) => wallet.walletAddress == kaikasWalletAddress
            )
          ) {
            WarningToastify("이미 추가된 지갑주소입니다.");
            return;
          } else {
            setWalletList([newWalletData]);
          }
          setWalletList([...walletList, newWalletData]);
        }
      } else {
        return;
      }
    } catch (error) {
      // console.error("connectKaikas 오류:", error);
    }
  };

  const InstitutionRegister = async () => {
    if (
      name !== "" &&
      walletList.length !== 0 &&
      businessLicenseNum !== "" &&
      walletList.length !== 0 &&
      latitude !== 0 &&
      longitude !== 0 &&
      imgUrl !== ""
    ) {
      try {
        const data = {
          businessLicense: businessLicenseNum,
          name,
          image: imgUrl,
          agencyType,
          latitude,
          longitude,
          walletList,
        };

        const response = await axios.post(
          process.env.REACT_APP_SERVER_API + "/auth/agency/sign-up",
          JSON.stringify(data),
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
            },
          }
        );

        if (response.data.status == 200) {
          NotiToastify("회원가입이 완료되었습니다.");
          window.location.href = "/register/success";
        }
      } catch (error: any) {
        if (error.response.data.message) {
          NotiToastify(error.response.data.message);
        } else {
          alert("알 수 없는 에러가 발생했습니다.");
        }
      }
    } else if (businessLicenseNum.length !== 12) {
      NotiToastify("사업자 등록번호를 형식에 맞게 입력해주세요.");
      return;
    } else {
      NotiToastify("회원가입 폼을 모두 입력해주세요.");
      return;
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("login_user")) {
      alert("이미 로그인된 상태입니다.");
      window.location.href = "/";
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center">
      <Modal
        content={
          <SearchAddressModalContent
            setAddress={setAddress}
            setLongitude={setLongitude}
            setLatitude={setLatitude}
            setIsActiveSearchAddressPopUp={setIsActiveSearchAddressPopUp}
            isActiveSearchAddressPopUp={isActiveSearchAddressPopUp}
          />
        }
        isActiveModal={isActiveSearchAddressPopUp}
        closeModal={setIsActiveSearchAddressPopUp}
      />
      <Modal
        content={
          <PfpModalContent
            setImgUrl={setImgUrl}
            setIsActivePfpModal={setIsActivePfpModal}
          />
        }
        isActiveModal={isActivePfpModal}
        closeModal={setIsActivePfpModal}
      />
      <img src={six_steps_logo} className="w-[120px] h-[100px] mt-[60px]" />
      <h3 className="mt-[10px]">회원 정보 입력</h3>
      <div className="flex flex-col  mt-[120px]">
        <div className="flex mb-[50px]">
          <span className="register-form-name w-[140px]">회원 유형</span>
          <div className="bg-gray40 rounded-[20px] w-[120px] h-[32px] flex items-center justify-center text-center text-gray80">
            기관 회원
          </div>
        </div>
        <div className="flex">
          <span className="register-form-name w-[140px]">사업자 등록번호</span>
          <div className="flex flex-col">
            <div className="flex">
              <input
                type="text"
                className="rounded-[20px] w-[282px] pl-9 text-gray80 border h-[34px] border-gray-200"
                placeholder="ex ) 123-45-67891"
                value={businessLicenseNum}
                onChange={handleBusinessLicenseNumChange}
              />
            </div>
          </div>
        </div>

        <div className="flex mt-[40px]">
          <span className="register-form-name w-[140px]">지갑주소</span>
          <div className="flex flex-col space-y-5">
            {walletList.length > 0 && (
              <>
                {walletList.map((wallet, index) => (
                  <div key={index} className="flex">
                    {wallet.walletAddress && (
                      <div className="flex">
                        <input
                          type="text"
                          className="rounded-[20px] bg-[#DFDFDF] text-center text-[#909090] border h-[36px] border-gray-200 w-[420px]"
                          placeholder="지갑주소 입력"
                          value={wallet.walletAddress}
                          disabled
                        />
                        <div
                          className="ml-3 rounded-full cursor-pointer border-[#f5d9c6] border-[2px] text-brand100 w-[30px] h-[30px]  flex items-center justify-center"
                          onClick={() => {
                            // 해당 요소 삭제
                            const updatedWalletList = [...walletList];
                            updatedWalletList.splice(index, 1);
                            setWalletList(updatedWalletList);
                          }}
                        >
                          <span className="text-[36px] pb-1">-</span>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}

            <div className="flex flex-col w-full ">
              <div className="flex space-x-3  ">
                <button
                  type="button"
                  className="bg-metamask_color rounded-[10px] text-white h-[40px] px-8 flex items-center justify-center"
                  onClick={() => {
                    connectMetaMask();
                  }}
                >
                  <img src={metamask_logo} className="w-[20px] h-[20px]" />

                  <span className="ml-2">메타마스크 지갑 연동</span>
                </button>
                <button
                  type="button"
                  className="bg-kaikas_color rounded-[10px] text-white h-[40px] px-8 flex items-center justify-center"
                  onClick={() => {
                    connectKaikas();
                  }}
                >
                  <img src={kaikas_logo} className="w-[20px] h-[20px]" />
                  <span className="ml-2"> 카이카스 지갑 연동</span>
                </button>
              </div>
              <span className="text-brand100 text-[12px] mb-[80px] mt-[10px]">
                * 지갑 연동 버튼을 눌러서 지갑주소를 추가할 수 있습니다. <br />
                연결이 제대로 되지 않는다면 지갑의 도메인 연결상태를
                확인해주세요.
              </span>
            </div>
          </div>
        </div>
        <div className="flex">
          <span className="register-form-name w-[140px]">기관명</span>
          <div className="flex flex-col">
            <div className="flex">
              <input
                maxLength={20}
                type="text"
                className="rounded-[20px] w-[230px] pl-9 text-gray-400 border h-[34px] border-gray-200"
                placeholder="기관명 입력"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex mt-[60px]">
          <span className="register-form-name w-[140px]">기관분류</span>
          <div className="flex flex-col">
            <div className="flex">
              <select
                className="rounded-[20px] w-[230px]  pl-9 text-gray80 border h-[37px] border-gray-200"
                placeholder="기관 종류"
                onChange={(e: any) => {
                  setAgencyType(e.target.value);
                }}
              >
                <option defaultChecked value="RIGHTS_GROUP">
                  동물보호센터
                </option>
                <option value="FEED_COMPANY">사료업체</option>
              </select>
            </div>
          </div>
        </div>
        <div className="flex mt-[60px]">
          <span className="register-form-name w-[140px]">기본주소</span>
          <div className="flex flex-col">
            <div className="flex">
              <input
                type="text"
                defaultValue={address}
                className="rounded-[60px] w-[300px] text-center bg-gray40 text-gray-400 border h-[70px] border-gray-200"
              />
              <button
                type="button"
                className="bg-brand100 ml-[50px] text-white rounded-[20px] px-3 h-[30px]"
                onClick={() => {
                  setIsActiveSearchAddressPopUp(!isActiveSearchAddressPopUp);
                }}
              >
                주소 찾기
              </button>
            </div>
          </div>
        </div>

        <div className="flex mt-[50px]">
          <span className="register-form-name w-[140px]">프로필</span>
          <div className="flex flex-col">
            <div className="flex">
              {imgUrl !== "" ? (
                <img
                  src={imgUrl}
                  className="sm:w-[400px] sm:h-[400px] relative"
                  alt="선택한 이미지"
                />
              ) : (
                <div className="sm:w-[240px] sm:h-[240px] bg-[#F7F7F7] flex items-center justify-center">
                  이미지를 선택하세요
                </div>
              )}
              <button
                type="button"
                className="bg-brand100 ml-6 text-white rounded-[20px] px-4 h-[33px] flex items-center justify-center"
                onClick={() => fileInputRef.current?.click()}
              >
                <span className="mr-1">파일 업로드</span>
                <UploadSVG width={15} height={15} fillColor="white" />
              </button>
              <button
                type="button"
                className="bg-brand100 ml-6 text-white rounded-[20px] px-4 h-[33px] flex items-center justify-center'
              "
                onClick={() => {
                  setIsActivePfpModal(true);
                }}
              >
                <span className="mr-1"> 프로필 사진 고르기</span>
                <UploadSVG width={12} height={12} fillColor="white" />
              </button>
              <input
                type="file"
                className="hidden"
                ref={fileInputRef}
                onChange={handleFileChange}
              />
            </div>
          </div>
        </div>

        <div className="w-full flex items-center justify-center">
          <button
            type="button"
            className="bg-brand100 w-[130px] mt-[80px] mb-[130px] text-[22px] text-white rounded-[30px]   px-3  h-[40px]"
            onClick={() => {
              InstitutionRegister();
            }}
          >
            가입하기
          </button>
        </div>
      </div>
    </div>
  );
};

export default InstitutionRegister;
