import { useNavigate } from "react-router-dom";
import NotiToastify from "../../common/NotiToastify";
import { useTranslation, Trans } from "react-i18next";

const MainIntroduceRightSection = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  return (
    <div className="sm:w-[40%] h-full sm:ml-[5%] mt-[60px] sm:mt-0">
      <div className="flex flex-col">
        <span className="sm:text-[68px] text-[32px] font-extrabold">
          6 STEPS
        </span>
        <span className="sm:text-[48px] font-extrabold">
          <Trans>{t("page:main-title")}</Trans>
        </span>
      </div>

      <div className="flex flex-col">
        <span className="sm:mt-[73px]  font-medium sm:text-[20px]">
          <Trans>{t("page:main-content1")}</Trans>
        </span>
        <span className="font-medium sm:text-[20px] mt-[34px]">
          <Trans>{t("page:main-content2")}</Trans>
        </span>
      </div>

      <div className="flex mt-[70px] space-x-[19px]">
        <button
          type="button"
          className="main-right-content-button bg-brand100"
          onClick={() => {
            navigate("/nftList");
          }}
        >
          <Trans>{t("page:main-explore-button")}</Trans>
        </button>
        <button
          type="button"
          className="main-right-content-button bg-[#470A11]"
          onClick={() => {
            if (sessionStorage.getItem("login_user") == null) {
              NotiToastify("로그인을 먼저 진행해주세요");
            } else if (sessionStorage.getItem("login_user")) {
              navigate("/nftUpload");
            }
          }}
        >
          <Trans>{t("page:main-create-nft-button")}</Trans>
        </button>
      </div>
    </div>
  );
};

export default MainIntroduceRightSection;
