import Introduce from "../../components/UI/introduce/Introduce";

const IntroducePage = () => {
  return (
    <div>
      <Introduce />
    </div>
  );
};

export default IntroducePage;
