import KlaytnSetProvider from "./KlaytnSetProvider";
import KlaytnIsApprovalForAll from "./KlaytnIsApprovalForAll";
import KlaytnSetIsApprovalForAll from "./KlaytnSetIsApprovalForAll";
import SendSellNFTInfoToServer from "../SendSellNFTInfoToServer";
const router_abi = require("../../../../utils/contract/MarketplaceRouter.json");

const KlaytnSellNFT = async (
  price: any,
  nftId: any,
  date: any,
  serverNftId: any
) => {
  const router_contract = process.env.REACT_APP_KLAYTN_ROUTER_CONTRACT;
  const nft_contract = process.env.REACT_APP_KLAYTN_NFT_CONTRACT;
  try {
    const provider = await window.klaytn;
    const setP: any = await KlaytnSetProvider(provider);
    if (!setP.success) {
      return new Error(setP.Error);
    }

    let isApproved: any = await KlaytnIsApprovalForAll(router_contract);

    if (!isApproved.data.isApproved) {
      await KlaytnSetIsApprovalForAll(router_contract, true);
    }
    const p = setP.provider;
    const account = (await p.getAccounts())[0];
    const contract = new p.Contract(router_abi.abi, router_contract);
    contract.options.gas = 5000000;
    const result = await contract.methods
      .creationAuction(
        process.env.REACT_APP_BUY_AUCTION_BAOBAB_CONTRACT_ADDRESS,
        nftId,
        nft_contract,
        String(price * 10 ** 18),
        Number(24 * 60 * 60 * date),
        "0x0000000000000000000000000000000000000000"
      )
      .send({ from: account });

    if (result.status && result.transactionhash != "") {
      const data = {
        nftId: serverNftId,
        sellerWalletAddress: result.events._Create.returnValues.creater,
        dueDate: date,
        price: price,
        auctionNumber: result.events._Create.returnValues._index,
        saleTransactionHash: result.transactionHash,
        network: "KLAYTN",
      };

      const res = await SendSellNFTInfoToServer(data);

      return {
        success: true,
        data: {
          creator: result.events._Create.returnValues.creater,
          index: result.events._Create.returnValues._index,
        },
        transactionHash: result.transactionHash,
      };
    } else {
      return new Error(result);
    }
  } catch (err: any) {
    return new Error(err);
  }
};

export default KlaytnSellNFT;
